import { TestSubmoduleDetailsResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { Component } from "react";
import { ReorderListItemType, TestStatus } from "../../../types";
import { isTouchDevice } from "../../../utils";
import ButtonReorderList from "./ButtonReorderList";
import DragDropReorderList from "./DragDropReorderList";

type Props = {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    submit: (data: { reorderList: { answers: string[] } }) => void;
};

type State = {
    items: ReorderListItemType[];
    submitted: boolean;
    isTouchDevice: boolean;
};

export default class ReorderList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            items: props.test.answers.map((txt, index) => ({
                txt,
                key: index,
            })),
            submitted: false,
            isTouchDevice: isTouchDevice(),
        };
    }

    onResize = (e: UIEvent) => {
        const is = isTouchDevice();

        if (is && !this.state.isTouchDevice) {
            return this.setState({ isTouchDevice: true });
        }

        if (!is && this.state.isTouchDevice) {
            return this.setState({ isTouchDevice: false });
        }
    };

    componentDidMount(): void {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize);
    }

    handleSubmit = () => {
        this.setState({ submitted: true });
        this.props.submit({
            reorderList: { answers: this.state.items.map((item) => item.txt) },
        });
    };

    render() {
        const props = {
            onSubmit: this.handleSubmit,
            items: this.state.items,
            setItems: (items: ReorderListItemType[]) =>
                this.setState({ items }),
            submitted: this.state.submitted,
            status: this.props.status,
            test: this.props.test,
        };

        if (this.state.isTouchDevice) {
            return <ButtonReorderList {...props} />;
        }

        return <DragDropReorderList {...props} />;
    }
}
