import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    Button,
    IconButton,
    Snackbar,
    Stack,
    Typography,
} from "@mui/material";
import {
    SubmoduleDetailsResponse,
    TestSubmissionResponse,
    TestSubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { QuestionTestType } from "@teyalite/hackbio-common/dist/types/question-test-type.enum";
import { TestVerdict } from "@teyalite/hackbio-common/dist/types/test-verdict.enum";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { updateTestSubmoduleCreator } from "../../redux/classroom";
import { AppState } from "../../redux/store";
import { TestStatus } from "../../types";
import { postRequest } from "../../utils/http";
import FillInGap from "./question/FillInGap";
import MCQ from "./question/MCQ";
import ReorderList from "./question/ReorderList";
import TypeItIn from "./question/TypeItIn";
import { resetAccountMyCoursesStateCreator } from "../../redux/account";
import { resetHomeCoursesStateCreator } from "../../redux/home";

type Props = PropsFromRedux & {
    submodule: SubmoduleDetailsResponse;
    setSubmodule: (sub: SubmoduleDetailsResponse) => void;
};

type State = {
    currentIndex: number;
    statuses: { [key: string]: TestStatus };
    openSnack: boolean;
};

class SubmoduleTest extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentIndex: 0,
            openSnack: false,
            statuses: Object.fromEntries(
                (props.submodule.content as TestSubmoduleDetailsResponse[]).map(
                    (test) => [test.id, "default"]
                )
            ),
        };
    }

    submitTest = async (testData: {
        typeItIn?: { answer: string };
        mcq?: { answer: string };
        reorderList?: { answers: string[] };
        fillInGap?: { answers: string[] };
    }) => {
        const {
            submodule,
            updateTestSubmodule,
            resetAccountMyCoursesState,
            resetHomeCoursesState,
        } = this.props;
        const { currentIndex } = this.state;
        const tests = submodule.content as TestSubmoduleDetailsResponse[];
        const currentTest = tests[currentIndex];

        this.setState({
            openSnack: false,
            statuses: {
                ...this.state.statuses,
                [currentTest.id]: "loading",
            },
        });

        const data = {
            courseId: submodule.courseId,
            moduleId: submodule.moduleId,
            submoduleId: submodule.id,
            questionId: submodule.questionId,
            testId: currentTest.id,
            type: currentTest.type,
            ...testData,
        };

        try {
            const response = await postRequest<TestSubmissionResponse>(
                "/course/submit-test",
                data
            );

            const tmpTests = tests.slice();

            tmpTests[currentIndex].remainingTrials -= 1;
            tmpTests[currentIndex].answer = response.answer;
            tmpTests[currentIndex].passed =
                response.verdict === TestVerdict.Correct;

            this.props.setSubmodule({ ...submodule, content: tmpTests });

            this.setState({
                statuses: {
                    ...this.state.statuses,
                    [currentTest.id]:
                        response.verdict === TestVerdict.Correct
                            ? "default"
                            : "failed",
                },
            });

            // Update module's submodule in the store
            updateTestSubmodule({
                xp: response.xp,
                completed: response.completed,
                moduleId: submodule.moduleId,
                submoduleId: submodule.id,
                testId: currentTest.id,
            });

            if (response.completed || response.xp > 0) {
                resetAccountMyCoursesState();
                resetHomeCoursesState();
            }
        } catch (error: any) {
            console.log(error);

            this.setState({
                statuses: {
                    ...this.state.statuses,
                    [currentTest.id]: "default",
                },
                openSnack: this.state.openSnack
                    ? true
                    : tests[currentIndex].id === currentTest.id,
            });
        }
    };

    previousQuestion = () => {
        const { currentIndex } = this.state;
        this.setState({ currentIndex: currentIndex - 1 });
    };

    nextQuestion = () => {
        const { currentIndex } = this.state;

        this.setState({ currentIndex: currentIndex + 1 });
    };

    closeSnackbar = () => this.setState({ openSnack: false });

    displayTest = () => {
        const { submodule } = this.props;
        const { currentIndex, statuses } = this.state;

        const tests = submodule.content as TestSubmoduleDetailsResponse[];

        const currentTest = tests[currentIndex];

        if (currentTest.type === QuestionTestType.TypeItIn) {
            return (
                <TypeItIn
                    test={currentTest}
                    submit={this.submitTest}
                    status={statuses[currentTest.id]}
                />
            );
        }

        if (currentTest.type === QuestionTestType.MCQ) {
            return (
                <MCQ
                    test={currentTest}
                    status={statuses[currentTest.id]}
                    submit={this.submitTest}
                />
            );
        }

        if (currentTest.type === QuestionTestType.ReorderList) {
            return (
                <ReorderList
                    test={currentTest}
                    status={statuses[currentTest.id]}
                    submit={this.submitTest}
                />
            );
        }

        if (currentTest.type === QuestionTestType.FillInGap) {
            return (
                <FillInGap
                    test={currentTest}
                    status={statuses[currentTest.id]}
                    submit={this.submitTest}
                />
            );
        }

        return null;
    };

    render() {
        const { submodule } = this.props;
        const { currentIndex } = this.state;

        const tests = submodule.content as TestSubmoduleDetailsResponse[];

        const currentTest = tests[currentIndex];

        return (
            <Stack
                p={{ xs: 0.5, sm: 2 }}
                flex={1}
                spacing={{ xs: 4, sm: 4, md: 3 }}
            >
                {this.displayTest()}
                <Stack
                    spacing={{ xs: 2, sm: 1 }}
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "initial", sm: "space-between" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                >
                    {currentTest.passed ? (
                        <Alert severity="success">
                            {currentTest.xp === 0
                                ? "Answer submitted!"
                                : "Test Passed!"}
                        </Alert>
                    ) : currentTest.remainingTrials <= 0 ? (
                        <Alert severity="error">Question failed!</Alert>
                    ) : (
                        <>
                            {
                                currentTest.xp !== 0 ? (
                                    <Typography
                                        fontSize={{ xs: 14, sm: 16 }}
                                        color="#e65100"
                                    >
                                        {currentTest.remainingTrials}{" "}
                                        {currentTest.remainingTrials === 1
                                            ? "trial"
                                            : "trials"}{" "}
                                        remaining
                                    </Typography>
                                ) : null
                                // todo:add skip
                                // <Button
                                //     sx={{ textTransform: "none" }}
                                //     color="secondary"
                                //     size="small"
                                //     onClick={this.nextQuestion}
                                // >
                                //     Skip
                                // </Button>
                            }
                        </>
                    )}

                    <Snackbar
                        open={this.state.openSnack}
                        onClose={this.closeSnackbar}
                        autoHideDuration={4000}
                        color="info"
                        message="An unexpected error has occurred, try again"
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={this.closeSnackbar}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    />

                    <Stack
                        direction={{ xs: "row" }}
                        justifyContent={{ xs: "space-between", sm: "initial" }}
                        width={{ xs: "100%", sm: "auto" }}
                        spacing={1}
                    >
                        <Button
                            startIcon={<ArrowBackIcon />}
                            sx={{ textTransform: "none", fontWeight: "bold" }}
                            variant="contained"
                            disableElevation
                            color="warning"
                            size="small"
                            onClick={this.previousQuestion}
                            disabled={currentIndex === 0}
                        >
                            Previous Question
                        </Button>

                        <Button
                            sx={{ textTransform: "none", fontWeight: "bold" }}
                            endIcon={<ArrowForwardIcon />}
                            variant="contained"
                            disableElevation
                            color="warning"
                            size="small"
                            onClick={this.nextQuestion}
                            disabled={currentIndex === tests.length - 1}
                        >
                            Next Question
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}

const mapDispatchToProps = {
    updateTestSubmodule: updateTestSubmoduleCreator,
    resetAccountMyCoursesState: resetAccountMyCoursesStateCreator,
    resetHomeCoursesState: resetHomeCoursesStateCreator,
};

function mapStateToProps(state: AppState) {
    return {};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SubmoduleTest);
