import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import { IconButton, Link as MuiLink, Stack, Typography } from "@mui/material";
import { emailValidation } from "@teyalite/hackbio-common/dist/email-validtation";
import { passwordValidation } from "@teyalite/hackbio-common/dist/password-validation";
import { StatusCodes } from "http-status-codes";
import { ChangeEvent, Component, FormEvent } from "react";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "../../auth.context";
import BlueButton from "../../components/BlueButton";
import Input from "../../components/Input";
import BackdropLoading from "../../components/auth/BackdropLoading";
import AuthFooter from "../../components/auth/Footer";
import AuthLayout from "../../components/auth/Layout";
import PasswordInput from "../../components/auth/PasswordInput";
import {
    CONNECTION_FAILED,
    GITHUB_LOGIN_URL,
    GOOGLE_LOGIN_URL,
    IS_PRODUCTION,
} from "../../constants";
import { AuthContextType } from "../../types";
import { postRequest } from "../../utils/http";
import { sleep } from "../../utils/sleep";
import { theme } from "../../utils/theme";

type State = {
    errorMessage: string;

    email: string;
    emailError: boolean;
    emailErrorMessage: string;

    password: string;
    passwordError: boolean;
    passwordErrorMessage: string;

    triggered: boolean;
    loading: boolean;

    redirect: boolean;
};

type Props = {};

export default class Login extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",

            email: IS_PRODUCTION ? "" : "hello@gmail.com",
            emailError: IS_PRODUCTION,
            emailErrorMessage: emailValidation(""),

            password: IS_PRODUCTION ? "" : "hellohello",
            passwordError: IS_PRODUCTION,
            passwordErrorMessage: passwordValidation(""),

            triggered: false,
            loading: false,

            redirect: false,
        };
    }

    static contextType = AuthContext;

    /**
     * Handle email field changes
     * @param param0
     */
    onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = emailValidation(value);

        this.setState({
            email: value,
            emailError: Boolean(message),
            emailErrorMessage: message,
        });
    };

    /**
     * handle password changes
     * @param param0
     */
    onPasswordChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const message = passwordValidation(value);

        this.setState({
            password: value,
            passwordError: Boolean(message),
            passwordErrorMessage: message,
        });
    };

    /**
     * Submit signin form
     * @param event
     * @returns
     */
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { emailError, email, passwordError, password } = this.state;
        const { setUser } = this.context as AuthContextType;

        if (emailError || passwordError) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ loading: true, errorMessage: "" });

        try {
            const user = await postRequest("/auth/signin", {
                email,
                password,
            });

            setUser(user);

            // to make the user feel the loading effect
            await sleep(0.2);

            const red = new URLSearchParams(document.location.search).get(
                "redirect"
            );

            if (red) {
                return (document.location.href =
                    (IS_PRODUCTION ? "https://" : "http://") + red);
            }

            this.setState({
                redirect: true,
            });
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                [StatusCodes.BAD_REQUEST, StatusCodes.UNAUTHORIZED].includes(
                    error.response.status
                )
            ) {
                message = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            this.setState({
                loading: false,
                errorMessage: message,
            });
        }
    };

    render() {
        const {
            email,
            password,
            errorMessage,
            emailErrorMessage,
            emailError,
            passwordErrorMessage,
            passwordError,
            triggered,
            loading,
            redirect,
        } = this.state;

        const emailErr = emailError && triggered;
        const passwordErr = passwordError && triggered;

        if (redirect) {
            return <Navigate to="/" replace />;
        }

        return (
            <AuthLayout>
                <BackdropLoading open={loading} />

                <Typography variant="h4" fontWeight="bold">
                    Login
                </Typography>

                <Stack spacing={4}>
                    <Stack direction="row" spacing={3}>
                        <IconButton
                            size="large"
                            sx={{ background: "rgba(251, 233, 138, 0.7)" }}
                            LinkComponent={"a"}
                            href={GOOGLE_LOGIN_URL}
                        >
                            <GoogleIcon
                                fontSize="large"
                                sx={{ color: "#2F80ED" }}
                            />
                        </IconButton>

                        <IconButton
                            size="large"
                            sx={{ background: "rgba(109, 114, 224, 0.5)" }}
                            LinkComponent={"a"}
                            href={GITHUB_LOGIN_URL}
                        >
                            <GitHubIcon
                                fontSize="large"
                                sx={{ color: "#2F80ED" }}
                            />
                        </IconButton>
                    </Stack>

                    <Typography variant="h6" className="text-gray">
                        Or use your email
                    </Typography>

                    {errorMessage && (
                        <Typography
                            sx={{
                                color: theme.palette.error.main,
                                maxWidth: "420px",
                            }}
                        >
                            {errorMessage}
                        </Typography>
                    )}

                    <Stack
                        spacing={3}
                        maxWidth="400px"
                        component="form"
                        onSubmit={this.onSubmit}
                    >
                        <Input
                            placeholder="Email"
                            onChange={this.onEmailChange}
                            value={email}
                            error={emailErr}
                            helperText={emailErr ? emailErrorMessage : ""}
                            size="medium"
                            autoComplete="username"
                        />

                        <Stack spacing={1.5}>
                            <PasswordInput
                                error={passwordErr}
                                placeholder="Password"
                                value={password}
                                onChange={this.onPasswordChange}
                                helperText={
                                    passwordErr ? passwordErrorMessage : ""
                                }
                                size="medium"
                            />
                            <MuiLink
                                to={"/auth/pwd-recov"}
                                component={Link}
                                sx={{
                                    color: "black",
                                    fontSize: ".875em",
                                    textDecorationColor: "black",
                                    alignSelf: "flex-start",
                                }}
                            >
                                Forgot password?
                            </MuiLink>
                        </Stack>

                        <BlueButton type="submit">Login</BlueButton>
                    </Stack>
                </Stack>

                <Typography variant="h6" fontWeight="bold">
                    First time here,{" "}
                    <MuiLink
                        component={Link}
                        to="/auth/signup"
                        sx={styles.signup}
                    >
                        Sign Up
                    </MuiLink>
                </Typography>

                <AuthFooter />
            </AuthLayout>
        );
    }
}

const styles = {
    signup: {
        color: theme.palette.info.main,
        textDecorationColor: theme.palette.info.main,
    },
};
