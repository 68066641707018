import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CLink from "../components/CLink";

export default function NotFound() {
    return (
        <Stack spacing={5} pt={10} component={Container}>
            <Typography variant="h1" sx={styles.codeText}>
                404:
                <Box component={"br"} sx={styles.box} />
                {" Page not found"}
            </Typography>
            <Typography variant="h5">
                The requested url was not found
            </Typography>
            <Button
                LinkComponent={CLink}
                href={"/"}
                sx={styles.button}
                color="primary"
                role="button"
                variant="contained"
                disableElevation
            >
                Go to the home page
            </Button>
        </Stack>
    );
}

const styles = {
    codeText: { my: 3, fontSize: "2.5rem" },
    button: {
        alignSelf: "start",
        px: 3,
        mx: 2,
        textTransform: "none",
        fontWeight: "bold",
    },
    box: { display: { xs: "block", sm: "none" } },
};
