import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Badge } from "../types";

export default function BadgeItem({ image, title }: Badge) {
    return (
        <Stack alignItems="center" spacing={1.5} mr={5} mt={4}>
            <Box sx={{ position: "relative", width: "100px", height: "100px" }}>
                <CheckCircleIcon
                    color="primary"
                    sx={{ position: "absolute", right: "10px", top: "10px" }}
                />
                <img src={image} alt={title} width={100} height={100} />
            </Box>
            <Typography>{title}</Typography>
        </Stack>
    );
}
