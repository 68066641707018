import Button, { ButtonProps } from "@mui/material/Button";
import { theme } from "../utils/theme";
import { useMediaQuery } from "@mui/material";

export default function BorderBlueButton({ sx, size, ...props }: ButtonProps) {
  const match = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Button
      size={match ? "small" : size}
      sx={{
        px: 3,
        border: "3px solid #000000",
        color: "black",
        fontWeight: "bold",
        backgroundColor: theme.palette.warning.dark,
        ...sx,
      }}
      {...props}
    />
  );
}
