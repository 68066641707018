import Box from "@mui/material/Box";
import { IS_PRODUCTION } from "../../constants";

// todo: youtube video https://stackoverflow.com/questions/59025419/how-to-find-out-when-a-youtube-embedded-iframe-is-loaded
export default function VideoShow({
    src,
    title,
}: {
    src: string;
    title: string;
}) {
    const handleLoad = () => {
        if (!IS_PRODUCTION) {
            console.log("VIDEO LOADED");
        }
    };

    return (
        <Box sx={styles.box}>
            <iframe
                width="100%"
                height="100%"
                allowFullScreen
                src={src}
                title={title}
                onLoad={handleLoad}
            ></iframe>
        </Box>
    );
}

const styles = {
    box: {
        height: {
            xs: "350px",
            sm: "453px",
            md: "450px",
            width: "100%",
        },
    },
};
