import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: "inherit",
    },
    palette: {
        primary: {
            main: "#00AD61",
        },
        secondary: {
            main: "#000000",
        },
        // ligth blue color for the appbar
        warning: {
            main: "#dbedff",
            dark: "#BDDCFB",
            light: "rgb(239 247 255)",
        },
        // blue color for auth buttons
        info: {
            main: "#2F80ED",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1060,
            xl: 1200,
        },
    },
});
