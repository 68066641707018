import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import {
    Box,
    IconButton,
    Link as MuiLink,
    Stack,
    Typography,
} from "@mui/material";
import { emailValidation } from "@teyalite/hackbio-common/dist/email-validtation";
import { nameValidation } from "@teyalite/hackbio-common/dist/name-validation";
import { passwordValidation } from "@teyalite/hackbio-common/dist/password-validation";
import { StatusCodes } from "http-status-codes";
import { ChangeEvent, Component, FormEvent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import BlueButton from "../../components/BlueButton";
import Input from "../../components/Input";
import BackdropLoading from "../../components/auth/BackdropLoading";
import AuthFooter from "../../components/auth/Footer";
import AuthLayout from "../../components/auth/Layout";
import PasswordInput from "../../components/auth/PasswordInput";
import {
    CONNECTION_FAILED,
    GITHUB_LOGIN_URL,
    GOOGLE_LOGIN_URL,
} from "../../constants";
import { postRequest } from "../../utils/http";
import { theme } from "../../utils/theme";

type State = {
    errorMessage: string;

    name: string;
    nameError: boolean;
    nameErrorMessage: string;

    email: string;
    emailError: boolean;
    emailErrorMessage: string;

    password: string;
    passwordError: boolean;
    passwordErrorMessage: string;

    isResendingConfirmation: boolean;
    loading: boolean;
    success: boolean;
    triggered: boolean;
};

type Props = {};

export default class Signup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",

            name: "",
            nameError: true,
            nameErrorMessage: nameValidation(""),

            email: "",
            emailError: true,
            emailErrorMessage: emailValidation(""),

            password: "",
            passwordError: true,
            passwordErrorMessage: passwordValidation(""),

            isResendingConfirmation: false,
            loading: false,
            success: false,
            triggered: false,
        };
    }

    /**
     * Handle name field changes
     * @param param0
     */
    onNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = nameValidation(value);

        this.setState({
            name: value,
            nameError: Boolean(message),
            nameErrorMessage: message,
        });
    };

    /**
     * Handle email field changes
     * @param param0
     */
    onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = emailValidation(value);

        this.setState({
            email: value,
            emailError: Boolean(message),
            emailErrorMessage: message,
        });
    };

    /**
     * handle password changes
     * @param param0
     */
    onPasswordChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const message = passwordValidation(value);

        this.setState({
            password: value,
            passwordError: Boolean(message),
            passwordErrorMessage: message,
        });
    };

    /**
     * Submit signup form
     * @param event
     * @returns
     */
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { nameError, name, emailError, email, passwordError, password } =
            this.state;

        if (nameError || emailError || passwordError) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ loading: true, errorMessage: "", success: false });

        try {
            await postRequest("/auth/signup", {
                name,
                email,
                password,
            });

            this.setState({
                loading: false,
                success: true,
                errorMessage: "",
            });
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                [StatusCodes.BAD_REQUEST, StatusCodes.CONFLICT].includes(
                    error.response.status
                )
            ) {
                message = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            this.setState({
                loading: false,
                errorMessage: message,
            });
        }
    };

    /**
     * Send confirmation email again
     * @param e
     */
    sendConfirmation = async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        this.setState({ isResendingConfirmation: true });

        try {
            await postRequest("/auth/confirmation-code", {
                email: this.state.email,
            });
        } catch (error) {}

        this.setState({ isResendingConfirmation: false });
    };

    render() {
        const {
            name,
            email,
            password,
            nameError,
            nameErrorMessage,
            errorMessage,
            emailErrorMessage,
            emailError,
            passwordErrorMessage,
            passwordError,
            loading,
            success,
            triggered,
            isResendingConfirmation,
        } = this.state;

        const nameErr = nameError && triggered;
        const emailErr = emailError && triggered;
        const passwordErr = passwordError && triggered;

        if (success) {
            return (
                <AuthLayout>
                    <BackdropLoading open={loading} />

                    <Typography variant="h4" fontWeight="bold">
                        Check Your Email!
                    </Typography>

                    <Typography variant="h5" maxWidth={"600px"}>
                        We just sent an email to{" "}
                        <Box
                            component="br"
                            sx={{ display: { xs: "none", md: "block" } }}
                        />
                        <span style={{ fontWeight: "bold" }}>{email}</span>
                        <br />
                        Please confirm your email address by clicking on the
                        link we just sent
                    </Typography>

                    <Typography>
                        {"Didn't"} receive the email?{" "}
                        {!isResendingConfirmation && (
                            <MuiLink
                                href={"#"}
                                color={theme.palette.info.main}
                                onClick={this.sendConfirmation}
                                fontWeight="bold"
                                underline="hover"
                            >
                                resend the email
                            </MuiLink>
                        )}
                        {isResendingConfirmation && <strong>resending</strong>}
                    </Typography>
                </AuthLayout>
            );
        }

        return (
            <AuthLayout>
                <BackdropLoading open={loading} />

                <Typography variant="h4" fontWeight="bold">
                    Create an account
                </Typography>

                <Stack spacing={4}>
                    <Stack direction="row" spacing={3}>
                        <IconButton
                            size="large"
                            sx={{ background: "rgba(251, 233, 138, 0.7)" }}
                            LinkComponent={"a"}
                            href={GOOGLE_LOGIN_URL}
                        >
                            <GoogleIcon
                                fontSize="large"
                                sx={{ color: "#2F80ED" }}
                            />
                        </IconButton>

                        <IconButton
                            size="large"
                            sx={{ background: "rgba(109, 114, 224, 0.5)" }}
                            LinkComponent={"a"}
                            href={GITHUB_LOGIN_URL}
                        >
                            <GitHubIcon
                                fontSize="large"
                                sx={{ color: "#2F80ED" }}
                            />
                        </IconButton>
                    </Stack>

                    <Typography variant="h6" className="text-gray">
                        Or use your email
                    </Typography>

                    {errorMessage && (
                        <Typography
                            sx={{
                                color: theme.palette.error.main,
                                maxWidth: "420px",
                                px: 0.5,
                            }}
                        >
                            {errorMessage}
                        </Typography>
                    )}

                    <Stack
                        spacing={3}
                        maxWidth="400px"
                        component="form"
                        onSubmit={this.onSubmit}
                    >
                        <Input
                            placeholder="Name"
                            onChange={this.onNameChange}
                            value={name}
                            error={nameErr}
                            helperText={nameErr ? nameErrorMessage : ""}
                            size="medium"
                        />

                        <Input
                            placeholder="Email"
                            onChange={this.onEmailChange}
                            value={email}
                            error={emailErr}
                            helperText={emailErr ? emailErrorMessage : ""}
                            size="medium"
                            autoComplete="username"
                        />

                        <PasswordInput
                            error={passwordErr}
                            placeholder="Password"
                            value={password}
                            onChange={this.onPasswordChange}
                            helperText={passwordErr ? passwordErrorMessage : ""}
                            size="medium"
                        />

                        <BlueButton type="submit">Sign up</BlueButton>
                    </Stack>
                </Stack>

                <Typography variant="h6" fontWeight="bold">
                    Already have an account?
                    <MuiLink
                        component={Link}
                        to="/auth/login"
                        sx={styles.login}
                    >
                        Log in
                    </MuiLink>
                </Typography>

                <AuthFooter />
            </AuthLayout>
        );
    }
}

const styles = {
    login: {
        color: theme.palette.info.main,
        textDecorationColor: theme.palette.info.main,
    },
};
