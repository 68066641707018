import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import { PropsWithChildren } from "react";
import CLink from "./CLink";

export default function BlueButton({
    children,
    sx,
    href,
    type,
}: PropsWithChildren & {
    sx?: SxProps;
    href?: string;
    type?: "submit" | "reset" | "button";
}) {
    if (href) {
        return (
            <Button
                variant="contained"
                LinkComponent={CLink}
                href={href}
                color="info"
                size="large"
                sx={{ fontWeight: 600, px: 8, ...sx }}
                disableElevation
            >
                {children}
            </Button>
        );
    }

    return (
        <Button
            variant="contained"
            color="info"
            size="large"
            sx={{ fontWeight: 600, px: 8, ...sx }}
            disableElevation
            type={type}
        >
            {children}
        </Button>
    );
}
