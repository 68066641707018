import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
    CourseSubmoduleResponse,
    ProjectSubmissionResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ModuleType } from "@teyalite/hackbio-common/dist/types/module-type.enum";
import { isURL } from "class-validator";
import { CSSProperties, ChangeEvent, Fragment, useState } from "react";
import { postRequest } from "../../utils/http";
import { theme } from "../../utils/theme";
import QuestionTestBlueInput from "../QuestionTestBlueInput";
import Select from "../Select";
import ShowSubmission from "./ShowSubmission";
import SubmitButton from "./question/SubmitButton";

type Props = {
    submodules: CourseSubmoduleResponse[];
    submission?: ProjectSubmissionResponse;
    moduleType: ModuleType;
    currentSubmodule: CourseSubmoduleResponse;
    setSubmission: (s: ProjectSubmissionResponse) => void;
    onClose: () => void;
};

export default function ProjectSubmissionForm({
    submission,
    onClose,
    submodules,
    moduleType,
    currentSubmodule,
    setSubmission,
}: Props) {
    const project =
        submodules.find((sub) => sub.id === submission?.submoduleId) ??
        currentSubmodule;

    const [url, setUrl] = useState("");
    const [projectId, setProjectId] = useState<string | null>(
        moduleType === ModuleType.Project ? null : String(project.id)
    );
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    const ITEMS = submodules.map((sub) => ({
        label: sub.title,
        value: String(sub.id),
    }));

    const handleChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        setUrl(value);
    };

    const handleProjectIdChange = (event: SelectChangeEvent) => {
        setProjectId(
            event.target.value.trim().length === 0 ? null : event.target.value
        );
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            const res = await postRequest<ProjectSubmissionResponse>(
                "/course/submit-project",
                {
                    courseId: project.courseId,
                    moduleId: project.moduleId,
                    submoduleId: Number(projectId),
                    url: url,
                }
            );

            setSubmission(res);
            setIsLoading(false);
        } catch (error: any) {
            setMessage("An unexpected error has occured, try again");
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            open
            PaperProps={{ sx: styles.PaperSx }}
            onClose={onClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <Box sx={styles.header}>
                <DialogTitle id="scroll-dialog-title">
                    <Typography
                        noWrap
                        sx={{ maxWidth: 200 }}
                        fontSize="1.25rem"
                    >
                        Project Submission
                    </Typography>
                </DialogTitle>
                <IconButton
                    onClick={onClose}
                    disabled={isLoading}
                    color="error"
                    sx={styles.closeIconButton}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent>
                <DialogContentText
                    id="scroll-dialog-description"
                    component="div"
                    tabIndex={-1}
                >
                    <Box component={Stack} sx={styles.form} spacing={3} pb={2}>
                        {submission ? (
                            <ShowSubmission
                                submission={submission}
                                project={project}
                                onClose={onClose}
                            />
                        ) : (
                            <Fragment>
                                <Typography>
                                    Submit the raw github link to your solution
                                    to the task below.
                                </Typography>
                                {moduleType === ModuleType.Project && (
                                    <Select
                                        items={ITEMS}
                                        value={String(projectId ?? "")}
                                        onChange={handleProjectIdChange}
                                        helperText="Project you want to make submission for"
                                        placeholder="Project"
                                    />
                                )}
                                <Stack spacing={1}>
                                    <QuestionTestBlueInput
                                        label="Link to your document"
                                        placeholder="https://github.com/mylink"
                                        onChange={handleChange}
                                        value={url}
                                    />

                                    <span
                                        style={
                                            styles.bottomText as CSSProperties
                                        }
                                    >
                                        You can only submit once, before
                                        submission be sure to open the link
                                        (click below) and check that it is
                                        correct
                                    </span>
                                </Stack>

                                <MuiLink
                                    href={url}
                                    target="_blank"
                                    rel="noreferrer"
                                    underline="hover"
                                    alignSelf="flex-start"
                                    color={theme.palette.info.dark}
                                >
                                    <Typography>
                                        Open submission link
                                    </Typography>
                                </MuiLink>

                                {message.length > 0 && (
                                    <Typography color="#d32f2f">
                                        {message}
                                    </Typography>
                                )}

                                <Stack
                                    direction="row"
                                    display={{ xs: "flex", sm: "none" }}
                                    justifyContent="flex-end"
                                    px={2}
                                    spacing={3}
                                >
                                    {isLoading ? (
                                        <LoadingButton
                                            loading
                                            variant="outlined"
                                            fullWidth
                                        >
                                            Submit
                                        </LoadingButton>
                                    ) : (
                                        <Fragment>
                                            <Button
                                                onClick={onClose}
                                                color="error"
                                                variant="contained"
                                                disableElevation
                                                sx={styles.closeButton}
                                            >
                                                Close
                                            </Button>

                                            {!submission && (
                                                <SubmitButton
                                                    disabled={
                                                        !isURL(url) ||
                                                        (moduleType ===
                                                            ModuleType.Project &&
                                                            !projectId)
                                                    }
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </SubmitButton>
                                            )}
                                        </Fragment>
                                    )}
                                </Stack>
                            </Fragment>
                        )}
                    </Box>
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={styles.DialogActions}>
                <Stack direction="row" spacing={5} pb={2} px={2}>
                    {isLoading ? (
                        <LoadingButton loading variant="outlined" fullWidth>
                            Submit
                        </LoadingButton>
                    ) : (
                        <Fragment>
                            <Button
                                onClick={onClose}
                                color="error"
                                variant="contained"
                                disableElevation
                                sx={styles.closeButton}
                            >
                                Close
                            </Button>

                            {!submission && (
                                <SubmitButton
                                    disabled={
                                        !isURL(url) ||
                                        (moduleType === ModuleType.Project &&
                                            !projectId)
                                    }
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </SubmitButton>
                            )}
                        </Fragment>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

const styles = {
    PaperSx: {
        minWidth: { xs: "100%", sm: 550 },
        minHeight: { xs: "100%", sm: "auto" },
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: 2,
        backgroundColor: "rgba(47, 128, 237, 0.2)",
    },
    closeIconButton: {
        textTransform: "none",
        marginRight: 3,
    },
    closeButton: {
        textTransform: "none",
        fontWeight: "bold",
    },
    form: {
        marginBottom: 1,
        minWidth: { md: 400 },
    },
    bottomText: {
        color: "#e65100",
        fontSize: 14,
    },
    DialogActions: {
        display: { xs: "none", sm: "flex" },
    },
};
