import MuiLink, { LinkProps } from "@mui/material/Link";
import { Link } from "react-router-dom";
import { forwardRef } from "react";

export default forwardRef(function CLink(
    { href, ...others }: LinkProps & { href: string },
    ref
) {
    return <MuiLink component={Link} to={href} {...others} />;
});
