import { Link, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

export default function Failed() {
    return (
        <Stack
            component={Container}
            sx={styles.root}
            spacing={styles.rootPadding}
            alignItems="center"
            textAlign="center"
        >
            <Typography variant="h4">
                Your transaction was not succesful 😫
            </Typography>
            <Typography maxWidth="600px" variant="h6">
                If you are still unable to process your payments, please write
                to{" "}
                <Link href="mailto:support@thehackbio.com" underline="hover">
                    support@thehackbio.com
                </Link>{" "}
                to provide you with manual options.
            </Typography>
        </Stack>
    );
}

const styles = {
    rootPadding: { xs: 4, sm: 4, md: 4 },
    root: { pt: { xs: 4, sm: 6 }, pb: 3 },
};
