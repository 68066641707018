import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { passwordValidation } from "@teyalite/hackbio-common/dist/password-validation";
import { isUUID } from "class-validator";
import { StatusCodes } from "http-status-codes";
import { ChangeEvent, Component, FormEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import Verified from "../../assets/icons/verified-blue.svg";
import BlueButton from "../../components/BlueButton";
import BackdropLoading from "../../components/auth/BackdropLoading";
import CountDown from "../../components/auth/CountDown";
import AuthLayout from "../../components/auth/Layout";
import PasswordInput from "../../components/auth/PasswordInput";
import { CONNECTION_FAILED } from "../../constants";
import { postRequest } from "../../utils/http";
import { theme } from "../../utils/theme";
import NotFound from "../NotFound";

type PageProps = {
    code: string;
    codeId: string;
};

type State = {
    errorMessage: string;

    password: string;
    passwordError: boolean;
    passwordErrorMessage: string;

    isLoading: boolean;
    success: boolean;
    triggered: boolean;
};

class PasswordResetComp extends Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            errorMessage: "",

            password: "",
            passwordError: true,
            passwordErrorMessage: passwordValidation(""),

            success: false,
            isLoading: false,
            triggered: false,
        };
    }

    /**
     * handle password changes
     * @param param0
     */
    onPasswordChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const message = passwordValidation(value);

        this.setState({
            password: value,
            passwordError: Boolean(message),
            passwordErrorMessage: message,
        });
    };

    /**
     * Submit password reset form
     * @param event
     * @returns
     */
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { passwordError, password } = this.state;

        if (passwordError) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ isLoading: true, errorMessage: "" });
        const { code, codeId } = this.props;

        try {
            await postRequest(`/auth/password-reset/${code}/${codeId}`, {
                password,
            });

            this.setState({ isLoading: false, success: true });
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                error.response.status === StatusCodes.NOT_FOUND
            ) {
                message = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            this.setState({
                isLoading: false,
                errorMessage: message,
            });
        }
    };

    /**
     * Reload in case there is a 500 error
     */
    refresh = () => {
        window.location.reload();
    };

    render() {
        const {
            password,
            errorMessage,
            passwordErrorMessage,
            passwordError,
            success,
            isLoading,
            triggered,
        } = this.state;

        const passwordErr = passwordError && triggered;

        if (success) {
            return (
                <AuthLayout sx={{ alignItems: "center", textAlign: "center" }}>
                    <Box>
                        <img
                            src={Verified}
                            alt="Verified"
                            width={24}
                            height={24}
                        />
                    </Box>
                    <Typography variant="h5" fontWeight="bold">
                        Your HackBio password has been reset
                    </Typography>
                    <Typography className="text-gray">
                        You will be redirected to the log in screen in{" "}
                        <CountDown seconds={5} redirect="/auth/login" /> seconds
                    </Typography>
                    <MuiLink
                        component={Link}
                        to="/auth/login"
                        sx={styles.loginLink}
                    >
                        Go to login screen
                    </MuiLink>
                </AuthLayout>
            );
        }

        return (
            <AuthLayout>
                <BackdropLoading open={isLoading} />
                <Typography sx={styles.title}>Reset your password</Typography>
                <Typography
                    variant="h6"
                    className="text-gray"
                    sx={{
                        maxWidth: "420px",
                    }}
                >
                    Enter your new password. After confirming, you will be asked
                    to log in again.
                </Typography>
                <Stack spacing={4}>
                    {errorMessage && (
                        <Typography sx={styles.errorMessage}>
                            {errorMessage}
                        </Typography>
                    )}

                    <Stack
                        spacing={3}
                        maxWidth="400px"
                        component="form"
                        onSubmit={this.onSubmit}
                    >
                        <PasswordInput
                            error={passwordErr}
                            placeholder="Password"
                            value={password}
                            onChange={this.onPasswordChange}
                            helperText={passwordErr ? passwordErrorMessage : ""}
                            size="medium"
                        />

                        <BlueButton type="submit">Reset password</BlueButton>
                    </Stack>
                </Stack>
            </AuthLayout>
        );
    }
}

const styles = {
    title: {
        fontWeight: "bold",
        fontSize: { xs: "1.75rem", sm: "2.125rem" },
    },
    errorMessage: {
        color: theme.palette.error.main,
        maxWidth: "420px",
    },
    loginLink: {
        color: theme.palette.info.main,
        textDecorationColor: theme.palette.info.main,
    },
};

export default function PasswordReset() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [code, codeId] = [params.get("code"), params.get("id")];

    if (!code || !codeId || code.trim().length === 0 || !isUUID(codeId)) {
        return <NotFound />;
    }

    return <PasswordResetComp code={code} codeId={codeId} />;
}
