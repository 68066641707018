import { Box } from "@mui/material";
import { IS_PRODUCTION } from "../../constants";

export default function SlideShow({ src, title }) {
    const handleLoad = () => {
        if (!IS_PRODUCTION) {
            console.log("SLIDE LOADED")
        }
    }

    return (
        <Box sx={styles.root}><iframe
            src={src}
            frameorder="0"
            width="100%"
            height="100%"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            title={title}
            onLoad={handleLoad}
        ></iframe>
        </Box>
    );
}

const styles = {
    root: { height: { xs: '350px', sm: "493px", md: "450px" } }
}