import {
    AccountAction,
    AccountActionType,
    AccountState,
    defaultAccountState,
} from "./types";

function accountReducer(
    state: AccountState = defaultAccountState,
    action: AccountAction
): AccountState {
    switch (action.type) {
        case AccountActionType.ResetAccountMyCoursesState:
            state = {
                ...state,
                myCourses: { ...defaultAccountState.myCourses },
            };

            return state;

        case AccountActionType.FetchHallOfFame:
            state = {
                ...state,
                hallOfFame: {
                    ...state.hallOfFame,
                    ...action.value,
                },
            };

            return state;
        case AccountActionType.FetchMyCourses:
            state = {
                ...state,
                myCourses: {
                    ...state.myCourses,
                    ...action.value,
                },
            };

            return state;

        default:
            return state;
    }
}

export default accountReducer;
