import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { nameValidation } from "@teyalite/hackbio-common/dist/name-validation";
import { usernameValidation } from "@teyalite/hackbio-common/dist/username-validation";
import { StatusCodes } from "http-status-codes";
import {
    ChangeEvent,
    Component,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import { AuthContext } from "../auth.context";
import Input from "../components/Input";
import Profile from "../components/Profile";
import BackdropLoading from "../components/auth/BackdropLoading";
import { CONNECTION_FAILED } from "../constants";
import { AuthContextType } from "../types";
import { extractResponseMessage } from "../utils";
import { getRequest, patchRequest } from "../utils/http";
import { theme } from "../utils/theme";
import Loading from "../components/Loading";
import CLink from "../components/CLink";

// todo: Billing information

type State = {
    errorMessage: string;

    name: string;
    nameError: boolean;
    nameErrorMessage: string;

    username: string;
    usernameError: boolean;
    usernameErrorMessage: string;

    isSavingGeneral: boolean;
    triggered: boolean;
};

type Props = {};

export default class AccountBilling extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",

            name: "",
            nameError: false,
            nameErrorMessage: "",

            username: "",
            usernameError: false,
            usernameErrorMessage: "",

            isSavingGeneral: false,
            triggered: false,
        };
    }

    componentDidMount(): void {
        const { name, username } = (this.context as AuthContextType).user!;
        this.setState({ name, username });
    }

    static contextType = AuthContext;

    /**
     * Handle name field changes
     * @param param0
     */
    onNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = nameValidation(value);

        this.setState({
            name: value,
            nameError: Boolean(message),
            nameErrorMessage: message,
        });
    };

    /**
     * Handle username field changes
     * @param param0
     */
    onUsernameChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const message = usernameValidation(value);

        this.setState({
            username: value.trim(),
            usernameError: Boolean(message),
            usernameErrorMessage: message,
        });
    };

    submitGeneral = async () => {
        const context = this.context as AuthContextType;
        const { usernameError, username, name } = this.state;

        const nameErrorMessage = nameValidation(name.trim());

        if (Boolean(nameErrorMessage) || usernameError) {
            return this.setState({
                triggered: true,
                nameError: Boolean(nameErrorMessage),
                nameErrorMessage,
            });
        }

        this.setState({ isSavingGeneral: true, errorMessage: "" });

        try {
            await patchRequest("/account/profile", {
                username,
                name: name.trim(),
            });

            context.setUser({ ...context.user!, username, name });
            this.setState({ isSavingGeneral: false });
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                error.response.data &&
                error.response.status !== StatusCodes.INTERNAL_SERVER_ERROR
            ) {
                message = extractResponseMessage(error.response.data.message);
            }

            this.setState({ isSavingGeneral: false, errorMessage: message });
        }
    };

    render() {
        const {
            name,
            nameError,
            nameErrorMessage,
            username,
            usernameError,
            usernameErrorMessage,
            triggered,
            errorMessage,
            isSavingGeneral,
        } = this.state;

        const { email, ...user } = (this.context as AuthContextType).user!;

        const nameErr = nameError && triggered;
        const usernameErr = usernameError && triggered;

        return (
            <Stack
                component={Container}
                pt={{ xs: 3, sm: 4 }}
                pb={3}
                spacing={{ xs: 6, sm: 8 }}
            >
                <Profile title="Accounts and Billing" />
                <BackdropLoading open={isSavingGeneral} />
                <Stack spacing={3}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={{ xs: 2, md: 0 }}
                    >
                        <Typography
                            fontSize="24px"
                            fontWeight="bold"
                            flex={1}
                            height="42.5px"
                        >
                            General
                        </Typography>

                        {(user.username !== username.trim() ||
                            user.name !== name.trim()) && (
                            <Box flex={3}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignSelf: "center",
                                        px: 3,
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                    size="large"
                                    disableElevation
                                    onClick={this.submitGeneral}
                                >
                                    Save changes
                                </Button>
                            </Box>
                        )}
                    </Stack>

                    {errorMessage.length > 0 && (
                        <Typography
                            color={theme.palette.error.main}
                            textAlign="center"
                        >
                            {errorMessage}
                        </Typography>
                    )}

                    <KeyValue label="Email">
                        <Input
                            disabled
                            placeholder="Email"
                            value={email}
                            size="small"
                        />
                    </KeyValue>
                    <KeyValue label="Name">
                        <Input
                            placeholder="Name"
                            onChange={this.onNameChange}
                            value={name}
                            error={nameErr}
                            helperText={nameErr ? nameErrorMessage : ""}
                            size="small"
                        />
                    </KeyValue>
                    <KeyValue label="Username">
                        <Input
                            placeholder="Username"
                            onChange={this.onUsernameChange}
                            value={username}
                            error={usernameErr}
                            helperText={usernameErr ? usernameErrorMessage : ""}
                            size="small"
                        />
                    </KeyValue>
                </Stack>

                <Stack spacing={3}>
                    <Typography
                        fontSize="24px"
                        fontWeight="bold"
                        flex={1}
                        height="42.5px"
                        id="billing-section"
                    >
                        Billing
                    </Typography>

                    <Billing />
                </Stack>
            </Stack>
        );
    }
}

function KeyValue({ label, children }: PropsWithChildren & { label: string }) {
    return (
        <Stack
            spacing={{ sm: 0, xs: 2 }}
            direction={{ xs: "column", sm: "row" }}
        >
            <Typography flex={1} fontSize="20px" fontWeight="600">
                {label}
            </Typography>
            <Stack flex={3}>{children}</Stack>
        </Stack>
    );
}

function Billing() {
    const { card, isLoading, hasFaild, retry } = useBilling();

    if (isLoading || hasFaild) {
        return (
            <Loading failed={hasFaild} onRetry={retry}>
                <Typography>
                    Failed to load billing details, try again.
                </Typography>
            </Loading>
        );
    }

    if (!card) {
        return (
            <Button
                LinkComponent={CLink}
                href="/add-card?redirect=/account#billing-section"
                variant="contained"
                disableElevation
                sx={{
                    alignSelf: "center",
                    px: 3,
                    textTransform: "none",
                    color: "white",
                    fontWeight: "bold",
                }}
            >
                Add Billing Card
            </Button>
        );
    }

    return (
        <>
            <KeyValue label="Preferred Merchant">Stripe</KeyValue>
            <KeyValue label="Payment Method">{card}</KeyValue>
        </>
    );
}

function useBilling() {
    const [card, setCard] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [hasFaild, seHasFaild] = useState(true);

    const request = async () => {
        setIsLoading(true);
        seHasFaild(false);

        try {
            const data = await getRequest("/payment/card");
            setCard(data?.card);
        } catch (error) {
            console.log(error);
            seHasFaild(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        request();
    }, []);

    return { card, isLoading, hasFaild, retry: () => request() };
}
