import LoadingButton from "@mui/lab/LoadingButton";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
};

export default function SubmitButton({
    onClick,
    disabled = false,
    loading = false,
    children,
}: Props) {
    return (
        <LoadingButton
            variant="contained"
            sx={styles.submitButton}
            onClick={onClick}
            disableElevation
            disabled={disabled}
            loading={loading}
        >
            {children}
        </LoadingButton>
    );
}

const styles = {
    submitButton: {
        alignSelf: "flex-end",
        textTransform: "none",
        fontWeight: "bold",
        color: "white",
    },
};
