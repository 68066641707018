import ImportExportIcon from "@mui/icons-material/ImportExport";
import { IconButton, Stack, Typography } from "@mui/material";
import { arrayEquals } from "@teyalite/hackbio-common/dist/array-equals";
import {
    ReorderListAnswerReveal,
    TestSubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ReorderListItemType, TestStatus } from "../../../types";
import { theme } from "../../../utils/theme";
import SubmitButton from "./SubmitButton";
import { FeedbackSurvey, Reveal, Thanks, WrongText } from "./Verdict";

type Props = {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    onSubmit: () => void;
    submitted: boolean;
    items: ReorderListItemType[];
    setItems: (items: ReorderListItemType[]) => void;
};

export default function ButtonReorderList({
    test,
    submitted,
    onSubmit,
    status,
    items,
    setItems,
}: Props) {
    const handleSwap = (top: number, bottom: number) => {
        const l = items.slice();

        [l[top], l[bottom]] = [l[bottom], l[top]];
        setItems(l);
    };

    if (test.answer && test.xp === 0) {
        const ans = test.answer as ReorderListAnswerReveal;
        const ITEMS =
            status === "failed" && submitted
                ? items
                : ans.list.map((txt, index) => ({ txt, key: index }));

        return (
            <Stack flexGrow={1} spacing={3}>
                <Typography fontWeight="bold">{test.question}</Typography>

                <Stack>
                    {ITEMS.map((item, index) => (
                        <ListItem
                            key={item.key}
                            item={item}
                            showIcon={index !== ITEMS.length - 1}
                            disabled
                        />
                    ))}
                </Stack>

                <Thanks />
            </Stack>
        );
    }

    if (test.answer) {
        const ans = test.answer as ReorderListAnswerReveal;

        const ITEMS =
            status === "failed" && submitted
                ? items
                : ans.list.map((txt, index) => ({ txt, key: index }));

        return (
            <Stack flexGrow={1} spacing={3}>
                <FeedbackSurvey />

                <Typography fontWeight="bold">{test.question}</Typography>

                <Stack>
                    {ITEMS.map((item, index) => (
                        <ListItem
                            key={item.key}
                            item={item}
                            showIcon={index !== ITEMS.length - 1}
                            disabled
                        />
                    ))}
                </Stack>

                <Reveal
                    correct={arrayEquals<string>(
                        items.map((item) => item.txt),
                        ans.list
                    )}
                    submitted={submitted}
                    explanation={ans.explanation}
                >
                    <Stack component="ol" marginLeft={2}>
                        {ans.list.map((txt, index) => (
                            <Typography
                                component="li"
                                key={index}
                                marginLeft={3}
                            >
                                {txt}
                            </Typography>
                        ))}
                    </Stack>
                </Reveal>
            </Stack>
        );
    }

    return (
        <Stack flexGrow={1} spacing={3}>
            {test.xp === 0 && <FeedbackSurvey />}

            <Typography fontWeight="bold">{test.question}</Typography>

            <Stack>
                {items.map((item, index) => (
                    <ListItem
                        key={item.key}
                        item={item}
                        showIcon={index !== items.length - 1}
                        onSwap={() => handleSwap(index, index + 1)}
                    />
                ))}
            </Stack>

            {status === "failed" && <WrongText />}

            <SubmitButton onClick={onSubmit} loading={status === "loading"}>
                Submit Answer
            </SubmitButton>
        </Stack>
    );
}

type ListItemProps = {
    showIcon?: boolean;
    item: ReorderListItemType;
    onSwap?: () => void;
    disabled?: boolean;
};

function ListItem({
    showIcon = false,
    disabled = false,
    item,
    onSwap,
}: ListItemProps) {
    // icon button key index, index + 1
    return (
        <Stack>
            <Typography sx={styles.itemText}>{item.txt}</Typography>

            {showIcon && (
                <IconButton
                    sx={styles.swapButton}
                    onClick={onSwap}
                    disabled={disabled}
                >
                    <ImportExportIcon />
                </IconButton>
            )}
        </Stack>
    );
}

const styles = {
    itemText: {
        background: theme.palette.warning.light,
        py: 1,
        px: { xs: 1, sm: 2 },
        border: "solid 1px gray",
        borderRadius: 1,
    },
    swapButton: { alignSelf: "center" },
};
