import { Stack, Typography } from "@mui/material";
import {
    TestSubmoduleDetailsResponse,
    TypeItInAnswerReveal,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ChangeEvent, useState } from "react";
import { TestStatus } from "../../../types";
import QuestionTestBlueInput from "../../QuestionTestBlueInput";
import SubmitButton from "./SubmitButton";
import {
    CaseWarning,
    FeedbackSurvey,
    Reveal,
    Thanks,
    WrongText,
} from "./Verdict";

export default function TypeItIn({
    test,
    submit,
    status,
}: {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    submit: (data: { typeItIn: { answer: string } }) => void;
}) {
    const [answer, setAnswer] = useState("");

    const handleChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        setAnswer(value);
    };

    const handleSubmit = async () => {
        submit({ typeItIn: { answer } });
    };

    if (test.answer && test.xp === 0) {
        return (
            <Stack flexGrow={1} spacing={4}>
                <FeedbackSurvey />
                <Typography fontWeight="bold">{test.question}</Typography>
                <Thanks />
            </Stack>
        );
    }

    if (test.answer) {
        const ans = test.answer as TypeItInAnswerReveal;
        const submitted = Boolean(answer);

        return (
            <Stack flexGrow={1} spacing={2}>
                <Typography fontWeight="bold">{test.question}</Typography>

                <Stack spacing={2}>
                    <CaseWarning />

                    <QuestionTestBlueInput
                        placeholder={submitted ? "Your answer" : "Answer"}
                        onChange={handleChange}
                        value={Boolean(answer) ? answer : ans.answer}
                        disabled
                    />

                    <Reveal
                        submitted={submitted}
                        correct={answer === ans.answer}
                        explanation={ans.explanation}
                    >
                        <strong>{ans.answer}</strong>
                    </Reveal>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack flexGrow={1} spacing={3}>
            {test.xp === 0 && <FeedbackSurvey />}

            <Typography fontWeight="bold">{test.question}</Typography>

            <Stack spacing={1}>
                {test.xp !== 0 && <CaseWarning />}

                <QuestionTestBlueInput
                    placeholder="Your answer"
                    onChange={handleChange}
                    value={answer}
                />

                {status === "failed" && <WrongText />}

                <SubmitButton
                    onClick={handleSubmit}
                    disabled={answer.length === 0}
                    loading={status === "loading"}
                >
                    Submit Answer
                </SubmitButton>
            </Stack>
        </Stack>
    );
}
