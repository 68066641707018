/**
 * Course outline is displayed on the top of the module content
 * Optimized
 */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CLink from "../../components/CLink";
import { ForwardedRef, forwardRef } from "react";
import { Link } from "react-router-dom";

type Props = {
    link: string;
    prev: string;
    next: string;
};

const CourseOutline = forwardRef(function Outline(
    { link, prev, next }: Props,
    ref: ForwardedRef<any>
) {
    const prevDisabled = !Boolean(prev);
    const nextDisabled = !Boolean(next);

    return (
        <Stack direction="row" className="bold-border" sx={styles.root}>
            <IconButton
                size="small"
                sx={styles.iconButton}
                disabled={prevDisabled}
                LinkComponent={CLink}
                href={prev}
            >
                <ArrowBackIcon sx={prevDisabled ? {} : styles.icon} />
            </IconButton>

            <CLink href={link} sx={styles.link}>
                <Stack
                    direction="row"
                    spacing={1.5}
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <MenuIcon sx={styles.icon} />
                    <Typography fontWeight="bold">Course Outline</Typography>
                </Stack>
            </CLink>

            {!nextDisabled && <Link ref={ref} to={next} hidden />}

            <IconButton
                size="small"
                sx={styles.iconButton}
                disabled={nextDisabled}
                LinkComponent={CLink}
                href={next}
            >
                <ArrowForwardIcon sx={nextDisabled ? {} : styles.icon} />
            </IconButton>
        </Stack>
    );
});

const styles = {
    root: {
        alignSelf: "flex-start",
        bgcolor: "#FCF4DB",
        width: { xs: "100%", md: "auto" },
    },
    link: {
        borderLeft: "3px solid #000000",
        borderRight: "3px solid #000000",
        px: { xs: 1, sm: 2 },
        color: "inherit",
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
    },
    iconButton: { px: 1 },
    icon: { color: "black" },
};

export default CourseOutline;
