import { Link, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

export default function Successful() {
    return (
        <Stack
            component={Container}
            sx={styles.root}
            spacing={styles.rootPadding}
            alignItems="center"
            textAlign="center"
        >
            <Typography variant="h4">
                Your transaction was Succesful 😊 ✅
            </Typography>
            <Typography maxWidth="600px" variant="h6">
                Watch out for our follow up email (in the next few seconds).
                <br />
            </Typography>
            <Typography>
                For refund, kindly write{" "}
                <Link underline="hover" href="mailto:support@thehackbio.com">
                    support@thehackbio.com
                </Link>{" "}
                to process it. (process takes 20-30 working days).
            </Typography>
        </Stack>
    );
}

const styles = {
    rootPadding: { xs: 4, sm: 4, md: 4 },
    root: { pt: { xs: 4, sm: 6 }, pb: 3 },
};
