import Close from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext } from "react";
import { AuthContextType, NavBarItemType } from "../../types";
import DrawerNavItem from "./DrawerNavItem";
import Logo from "../Logo";
import NavBarItem, { NAV_ITEM_STYLES } from "./NavBarItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ReactComponent as HomeLogo } from "../../assets/icons/home.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../utils/theme";
import {
    ABOUT_URL,
    ALL_COURSES,
    BLOG_URL,
    BOOTCAMPS_URL,
    CONTACT_URL,
    EVENTS_URL,
    PATH_FINDER,
    SPEAK_TO_MENTOR,
    STORIES_URL,
    MY_COURSES,
    HALL_OF_FAME,
    MY_ACCOUNT,
} from "../../constants";
import Typography from "@mui/material/Typography";
import { getRequest } from "../../utils/http";
import { AuthContext } from "../../auth.context";

export default function AppBar() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    /**
     * Open and Close the drawer
     */
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <MuiAppBar
            component="nav"
            sx={{
                background: "rgb(219 237 255)",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                py: 2,
            }}
        >
            <Toolbar component={Container}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                    spacing={2}
                >
                    <Logo
                        width={40}
                        height={40}
                        color="black"
                        fontWeight="bold"
                    />
                    <Stack
                        flexGrow={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        {!matches && (
                            <>
                                {navBarItems.map((data, index) => (
                                    <NavBarItem key={index} {...data} />
                                ))}
                            </>
                        )}
                    </Stack>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            display: { xs: "flex", md: "none" },
                        }}
                        onClick={toggleDrawer}
                    >
                        <MenuIcon fontSize="large" />
                    </IconButton>
                    <Drawer
                        anchor={"top"}
                        open={matches && drawerOpen}
                        onClose={toggleDrawer}
                        sx={{ width: "100%" }}
                    >
                        <Stack
                            direction="column"
                            pt={3}
                            pb={5}
                            component={Container}
                            spacing={3}
                        >
                            <Stack direction={"row"}>
                                <Stack flexGrow={1}>
                                    <Logo
                                        width={40}
                                        height={40}
                                        color="black"
                                        fontWeight="bold"
                                    />
                                </Stack>
                                <IconButton
                                    sx={{ mx: 5 }}
                                    onClick={toggleDrawer}
                                >
                                    <Close fontSize="large" />
                                </IconButton>
                            </Stack>
                            <Stack>
                                {navBarItems.map((data, index) => (
                                    <DrawerNavItem
                                        key={index}
                                        {...data}
                                        afterClick={() => {
                                            setDrawerOpen(false);
                                        }}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </Drawer>
                </Stack>
            </Toolbar>
        </MuiAppBar>
    );
}

function Account() {
    const user = (useContext(AuthContext) as AuthContextType).user;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {user?.image ? (
                <img
                    src={user.image}
                    alt={user.name}
                    width={24}
                    height={24}
                    style={{ borderRadius: 12 }}
                />
            ) : (
                <AccountCircleIcon />
            )}
            <Typography sx={NAV_ITEM_STYLES}>My account</Typography>
        </Stack>
    );
}

const navBarItems: NavBarItemType[] = [
    {
        label: (
            <Stack direction="row" spacing={1} alignItems="center">
                <HomeLogo width={24} height={24} />
                <Typography sx={NAV_ITEM_STYLES}>Home</Typography>
            </Stack>
        ),
        link: "/",
    },
    {
        label: "My Courses",
        link: MY_COURSES,
    },
    {
        label: "Resources",
        list: [
            {
                label: "Courses",
                link: ALL_COURSES,
                isExternal: true,
            },
            {
                label: "Bootcamps",
                link: BOOTCAMPS_URL,
                isExternal: true,
            },
            {
                label: "Stories",
                link: STORIES_URL,
                isExternal: true,
            },
            {
                label: "Blog",
                link: BLOG_URL,
                isExternal: true,
            },
            {
                label: "Pathfinder",
                link: PATH_FINDER,
                isExternal: true,
            },
            {
                label: "Events",
                link: EVENTS_URL,
                isExternal: true,
            },
        ],
    },
    {
        label: "Help",
        list: [
            {
                label: "Talk to a mentor",
                link: SPEAK_TO_MENTOR,
                isExternal: true,
            },
            {
                label: "Contact Us",
                link: CONTACT_URL,
            },
            {
                label: "Pathfinder",
                link: PATH_FINDER,
            },
            {
                label: "About Us",
                link: ABOUT_URL,
            },
        ],
    },
    {
        label: <Account />,
        list: [
            {
                label: "Accounts and Billing",
                link: MY_ACCOUNT,
            },
            {
                label: "My Courses",
                link: MY_COURSES,
            },
            {
                label: "Hall of fame",
                link: HALL_OF_FAME,
            },
            {
                label: "Sign Out",
                onClick: async (e: any) => {
                    try {
                        await getRequest("/auth/logout");
                        window.location.href = window.location.origin + "/auth";
                    } catch (error) {}
                },
            },
        ],
    },
];
