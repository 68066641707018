import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { MouseEvent } from "react";
import { ReactComponent as CourseChairIcon } from "../assets/icons/course-chair.svg";
import { Certificate } from "../types";
import LinearProgressBar from "./LinearProgressBar";
import { COURSE_COLORS } from "../constants";

export default function CourseCertificate({
    color,
    course,
}: {
    color?: string;
    course: Certificate;
}) {
    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (course.certificate) {
            return;
        }

        e.preventDefault();

        // todo:feature add certificate not ready
        window.alert("Not ready");
    };

    return (
        <Stack
            className="bold-border"
            sx={styles.root(
                color ?? COURSE_COLORS[course.id % COURSE_COLORS.length]
            )}
            p={2}
            spacing={3}
            component={"a"}
            href={course.certificate ?? "#"}
            onClick={handleClick}
            target="_blank"
            rel="noreferrer"
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <CourseChairIcon width={50} height={50} />
                <Typography>Certificate →</Typography>
            </Stack>
            <Typography variant="h6" fontWeight="bold">
                {course.title}
            </Typography>
            <Stack spacing={2}>
                <LinearProgressBar value={course.progress} />
                <Stack direction="row" spacing={3} fontWeight="bold">
                    <Typography className="text-green">
                        ⌛️ {course.progress}%
                    </Typography>
                    <Typography>🚀 {course.xp}XP</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

const styles = {
    root: (color: string) => ({
        background: color,
        textDecoration: "none",
        color: "inherit",
    }),
};
