import { Box, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ChangeEvent } from "react";

// todo: max length attribute

type Props = {
    error?: boolean;
    placeholder: string;
    value: string;
    size?: "small" | "medium";
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    type?: string;
    autoComplete?: string;
    disabled?: boolean;
};

export default function Input({
    placeholder,
    size,
    value,
    type,
    onChange,
    error,
    helperText,
    autoComplete,
    disabled = false,
}: Props) {
    return (
        <FormControl size={size} variant="filled" color="info" error={error}>
            <Box
                style={{
                    backgroundColor: "white",
                    display: "flex",
                }}
            >
                <OutlinedInput
                    disabled={disabled}
                    style={{ width: "100%" }}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    autoComplete={autoComplete}
                    type={type}
                />
            </Box>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
