import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { CourseDetailsResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import CourseNotFound from "../../components/CourseNotFound";
import Loading from "../../components/Loading";
import { fetchCourseDetailsCreator } from "../../redux/classroom";
import { AppState } from "../../redux/store";
import { getRequest } from "../../utils/http";

type Props = PropsFromRedux & {
    courseId: number;
};

class ClassroomComp extends Component<Props> {
    componentDidMount(): void {
        const { courseId } = this.props;
        const { store } = this.props;

        if (store.courseId !== courseId) {
            this.fetchCourseDetails();
        }
    }

    fetchCourseDetails = async () => {
        const { courseId, fetch } = this.props;

        fetch({
            ...this.props.store,
            courseId,
            hasFailed: false,
            isLoading: true,
        });

        try {
            const { course } = await getRequest<{
                course: CourseDetailsResponse | null;
            }>("/course/" + courseId);

            // If current course id is not equal to the one in the store
            // it means that it has been changed therefore this async is no longer the correct one
            if (courseId !== this.props.store.courseId) return;

            fetch({
                ...this.props.store,
                courseId,
                isLoading: false,
                course: course,
            });
        } catch (error: any) {
            if (courseId !== this.props.store.courseId) return;

            fetch({
                ...this.props.store,
                courseId,
                hasFailed: true,
                isLoading: false,
            });
        }
    };

    // Link that point to the first uncompleted submodule
    resumeLink = () => "/";

    render() {
        const { store } = this.props;

        if (
            store.courseId === undefined ||
            store.isLoading ||
            store.hasFailed
        ) {
            return (
                <Stack
                    component={Container}
                    sx={styles.root}
                    spacing={{ xs: 6, sm: 8 }}
                >
                    <Stack py={4}>
                        <Loading
                            failed={store.hasFailed}
                            spacing={1}
                            onRetry={this.fetchCourseDetails}
                        />
                    </Stack>
                </Stack>
            );
        }

        if (!store.course) {
            return <CourseNotFound />;
        }

        return <Outlet />;
    }
}

const styles = {
    root: { pt: { xs: 3, sm: 4 }, pb: 3 },
};

const mapDispatchToProps = {
    fetch: fetchCourseDetailsCreator,
};

function mapStateToProps(state: AppState) {
    return {
        store: {
            ...state.classroom.details,
            courseId: state.classroom.courseId,
        },
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ClassroomConnected = connector(ClassroomComp);

export default function Classroom() {
    const { courseId } = useParams();

    const id = Number(courseId);

    if (isNaN(id)) {
        return <CourseNotFound />;
    }

    return <ClassroomConnected courseId={id} />;
}
