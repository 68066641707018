import FailedIcon from "../assets/images/failed-icon.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function FailedRetry({ onRetry }: { onRetry: () => void }) {
    return (
        <Stack spacing={3} alignItems="center">
            <img
                src={FailedIcon}
                alt=""
                width={100}
                style={{ marginBottom: 20 }}
            />
            <Typography variant="h5">The connection failed</Typography>
            <Button
                sx={{ px: 3, py: 1, fontWeight: "bold" }}
                startIcon={<RefreshIcon />}
                color="info"
                size="large"
                onClick={onRetry}
            >
                Retry
            </Button>
        </Stack>
    );
}
