import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { PropsWithChildren } from "react";
import AppBar from "./navigation/AppBar";

export default function AppBarLayout({ children }: PropsWithChildren) {
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
            }}
        >
            <AppBar />
            <Stack
                sx={{ paddingTop: 12, pb: 10, width: "100%" }}
                direction={"column"}
                alignItems="center"
            >
                {children}
            </Stack>
        </Box>
    );
}
