import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { NavBarItemType } from "../../types";
import { theme } from "../../utils/theme";
import CLink from "../CLink";

export const NAV_ITEM_STYLES: SxProps = {
    fontWeight: 600,
    my: 1,
    color: "black",
    textTransform: "none",
    "&:hover": {
        color: theme.palette.primary.main,
        background: "inherit",
    },
};

export default function NavBarItem({
    label,
    link,
    isExternal = false,
    list,
    onClick,
    afterClick,
}: NavBarItemType) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
        if (afterClick) afterClick();
    };

    const handleAfterClick = () => {
        setAnchorEl(null);
        if (afterClick) afterClick();
    };

    if (list && list.length > 0) {
        return (
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="text"
                    disableElevation
                    size="large"
                    sx={NAV_ITEM_STYLES}
                    onClick={handleOpen}
                    endIcon={
                        typeof label !== "string" ? null : (
                            <KeyboardArrowDownIcon />
                        )
                    }
                >
                    {label}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                >
                    {list.map((data, index) => (
                        <NavBarItem
                            {...data}
                            key={"nested" + index}
                            afterClick={() => {
                                handleAfterClick();
                                if (data.afterClick) data.afterClick();
                            }}
                        />
                    ))}
                </Menu>
            </div>
        );
    }

    if (isExternal) {
        return (
            <MenuItem
                component={"a"}
                href={link as string}
                target="_blank"
                rel="noreferrer"
                key={link as string}
                sx={NAV_ITEM_STYLES}
                onClick={handleClick}
            >
                {label}
            </MenuItem>
        );
    }

    return (
        <MenuItem
            component={CLink}
            href={link as string}
            sx={NAV_ITEM_STYLES}
            onClick={(event) => {
                if (onClick) onClick(event);
                handleClick(event);
            }}
        >
            {label}
        </MenuItem>
    );
}
