import { Dispatch } from "react";
import {
    FetchRecommendations,
    HomeActionType,
    HomeCoursesState,
    HomeRecommendationsState,
    FetchCourses,
    ResetHomeCoursesState,
} from "./types";

export const fetchRecommendationsCreator = (
    payload: HomeRecommendationsState
) => {
    return (dispatch: Dispatch<FetchRecommendations>) => {
        dispatch({
            value: payload,
            type: HomeActionType.FetchRecommendations,
        });
    };
};

export const fetchCoursesCreator = (payload: HomeCoursesState) => {
    return (dispatch: Dispatch<FetchCourses>) => {
        dispatch({
            value: payload,
            type: HomeActionType.FetchCourses,
        });
    };
};

export const resetHomeCoursesStateCreator = () => {
    return (dispatch: Dispatch<ResetHomeCoursesState>) => {
        dispatch({
            type: HomeActionType.ResetHomeCoursesState,
        });
    };
};
