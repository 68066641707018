import { USER_NAME_FIELD } from './constants';
import { requiredErrorMessage } from './error-messages';

/**
 * Validate name
 * @param name
 * @returns
 */
export function nameValidation(name: string) {
  if (name.length === 0) {
    return requiredErrorMessage(USER_NAME_FIELD);
  }

  return '';
}
