import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
    MCQAnswerReveal,
    TestSubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ChangeEvent, useState } from "react";
import { TestStatus } from "../../../types";
import SubmitButton from "./SubmitButton";
import { FeedbackSurvey, Reveal, Thanks, WrongText } from "./Verdict";

export default function MCQ({
    test,
    submit,
    status,
}: {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    submit: (data: { mcq: { answer: string } }) => void;
}) {
    const [value, setValue] = useState("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleSubmit = () => {
        submit({ mcq: { answer: value } });
    };

    if (test.answer && test.xp === 0) {
        return (
            <Stack flexGrow={1} spacing={2}>
                <FeedbackSurvey />
                <Typography fontWeight="bold" pt={1}>
                    {test.question}
                </Typography>

                <Thanks />
            </Stack>
        );
    }

    if (test.answer) {
        const ans = test.answer as MCQAnswerReveal;
        const submitted = Boolean(value);

        return (
            <Stack flexGrow={1} spacing={2}>
                <Typography fontWeight="bold" pt={1}>
                    {test.question}
                </Typography>

                <FormControl disabled>
                    <RadioGroup
                        value={submitted ? value : ans.answer}
                        onChange={handleChange}
                    >
                        {test.answers.map((str: string) => (
                            <FormControlLabel
                                key={str}
                                value={str}
                                control={<Radio color="info" />}
                                label={str}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                <Reveal
                    correct={value === ans.answer}
                    submitted={submitted}
                    explanation={ans.explanation}
                >
                    <strong>{ans.answer}</strong>
                </Reveal>
            </Stack>
        );
    }

    return (
        <Stack flexGrow={1} spacing={2}>
            {test.xp === 0 && <FeedbackSurvey />}

            <Typography fontWeight="bold" pt={1}>
                {test.question}
            </Typography>

            <FormControl>
                <RadioGroup value={value} onChange={handleChange}>
                    {test.answers.map((str: string) => (
                        <FormControlLabel
                            key={str}
                            value={str}
                            control={<Radio color="info" />}
                            label={str}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {status === "failed" && <WrongText />}

            <SubmitButton
                onClick={handleSubmit}
                disabled={value.length === 0}
                loading={status === "loading"}
            >
                Submit Answer
            </SubmitButton>
        </Stack>
    );
}
