import React from "react";
import { TERMS_AND_CONDITIONS } from "../../constants";
import Typography from "@mui/material/Typography";
import { theme } from "../../utils/theme";

export default function AuthFooter() {
    return (
        <Typography component="small" sx={styles.root} className="text-gray">
            By signing up for Hackbio you acknowledge that you have read and
            understood, and agreed to our{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href={TERMS_AND_CONDITIONS}
                style={styles.footerLink}
            >
                terms and conditions
            </a>
            .
        </Typography>
    );
}

const styles = {
    root: { maxWidth: "420px", fontSize: "medium" },
    footerLink: {
        color: theme.palette.info.main,
        textDecoration: "underline",
        textDecorationColor: theme.palette.info.main,
    },
};
