import { Component } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Protected } from "./auth.context";
import Classroom from "./components/classroom/Classroom";
import AccountBilling from "./pages/AccountBilling";
import AddCard from "./pages/AddCard";
import HallOfFame from "./pages/HallOfFame";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Confirmation from "./pages/auth/Confirmation";
import Login from "./pages/auth/Login";
import PasswordRecovery from "./pages/auth/PasswordRecovery";
import PasswordReset from "./pages/auth/PasswordReset";
import Signup from "./pages/auth/Signup";
import Checkout from "./pages/classroom/Checkout";
import CourseModule from "./pages/classroom/CourseModule";
import Failed from "./pages/classroom/Failed";
import MyCourse from "./pages/classroom/MyCourse";
import MyCourses from "./pages/classroom/MyCourses";
import Successful from "./pages/classroom/Successful";

export default class App extends Component {
    componentDidMount() {
        try {
            document.querySelector("#loading")?.remove();
        } catch (_err) {}
    }

    render() {
        return (
            <Routes>
                <Route path="/" element={<Protected />}>
                    <Route index element={<Home />} />
                    <Route path="/hall-of-fame" element={<HallOfFame />} />
                    <Route path="/account" element={<AccountBilling />} />
                    <Route path="/classroom" element={<Outlet />}>
                        <Route index element={<MyCourses />} />
                        <Route path=":courseId" element={<Classroom />}>
                            <Route index element={<MyCourse />} />
                            <Route path="checkout" element={<Checkout />} />
                            <Route path="successful" element={<Successful />} />
                            <Route path="failed" element={<Failed />} />
                            <Route
                                path=":moduleId"
                                element={<CourseModule />}
                            />
                        </Route>
                    </Route>
                    <Route path="add-card" element={<AddCard />} />
                </Route>

                {/* Auth routes, no authentification check to proceed */}
                <Route path="/auth" element={<Outlet />}>
                    <Route index element={<Navigate to="/auth/login" />} />
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="pwd-reset" element={<PasswordReset />} />
                    <Route path="pwd-recov" element={<PasswordRecovery />} />
                    <Route path="confirmation" element={<Confirmation />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    }
}
