import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import CourseItem from "../../components/CourseItem";
import GreenButton from "../../components/GreenButton";
import Loading from "../../components/Loading";
import Profile from "../../components/Profile";
import { ALL_COURSES, COURSE_COLORS } from "../../constants";
import { fetchMyCoursesCreator } from "../../redux/account";
import { AppState } from "../../redux/store";
import { getRequest } from "../../utils/http";
import { Typography } from "@mui/material";
import { CourseResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";

type Props = {} & PropsFromRedux;

class MyCourses extends Component<Props> {
    componentDidMount(): void {
        const { isLoading, courses } = this.props.store;

        if (!isLoading && courses.length === 0) {
            this.fetchCourses();
        }
    }

    fetchCourses = async () => {
        const { fetch } = this.props;
        fetch({ ...this.props.store, hasFailed: false, isLoading: true });

        try {
            const courses = await getRequest<CourseResponse[]>("/course");

            fetch({
                ...this.props.store,
                isLoading: false,
                courses,
            });
        } catch (error: any) {
            fetch({ ...this.props.store, hasFailed: true, isLoading: false });
        }
    };

    render(): React.ReactNode {
        const { courses, isLoading, hasFailed } = this.props.store;

        return (
            <Stack
                component={Container}
                pt={{ xs: 3, sm: 4 }}
                pb={3}
                spacing={{ xs: 6, sm: 8 }}
            >
                <Profile title="My Courses (On-going and Completed)" />

                <MuiLink
                    href={ALL_COURSES}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        alignSelf: "center",
                    }}
                >
                    <GreenButton>Learn something New</GreenButton>
                </MuiLink>

                {isLoading || hasFailed ? (
                    <Stack py={4}>
                        <Loading
                            failed={hasFailed}
                            spacing={1}
                            onRetry={this.fetchCourses}
                        />
                    </Stack>
                ) : (
                    <>
                        {courses.length > 0 && (
                            <Grid
                                container
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 1, sm: 8, lg: 12 }}
                            >
                                {courses.map((course, index) => (
                                    <Grid xs={2} sm={4} md={4} key={index}>
                                        <CourseItem
                                            key={course.id}
                                            course={course}
                                            color={
                                                COURSE_COLORS[
                                                    index % COURSE_COLORS.length
                                                ]
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        {courses.length === 0 && (
                            <Typography variant="h6" alignSelf="center">
                                You don't have any on-going or completed course
                            </Typography>
                        )}
                    </>
                )}
            </Stack>
        );
    }
}

const mapDispatchToProps = {
    fetch: fetchMyCoursesCreator,
};

function mapStateToProps(state: AppState) {
    return { store: { ...state.account.myCourses } };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MyCourses);
