import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Fragment, PropsWithChildren } from "react";

export const CORRECT_TEXT = "was the correct answer";

export function CorrectText() {
    return (
        <Typography color="#27AE60">✅ Perfect! You are correct. ✨</Typography>
    );
}

export function WrongText() {
    return <Typography color="#EB5757">❌ Oh no! That was wrong!</Typography>;
}

export function CaseWarning() {
    return (
        <small style={{ color: "#ed6c02" }}>⚠️ Answer is case sensitive</small>
    );
}

export function Thanks() {
    return (
        <Typography color="#27AE60">
            ✅ Thank you for your answer! ❤️
        </Typography>
    );
}

export function FeedbackSurvey() {
    return <small style={{ color: "#ed6c02" }}>Feedback | Survey</small>;
}

type ExplanationProps = { explanation: string[] };

export function Explanation({ explanation }: ExplanationProps) {
    return (
        <Stack spacing={1}>
            <Typography fontWeight="bold">Explanations:</Typography>
            {explanation.length === 0 && (
                <Typography>No explanation</Typography>
            )}

            <Typography>
                {explanation.map((txt, index) => (
                    <Fragment key={index}>
                        {txt}
                        <br />
                    </Fragment>
                ))}
            </Typography>
        </Stack>
    );
}

type RevealProps = PropsWithChildren & {
    explanation: string[];
    correct: boolean;
    submitted: boolean;
};

export function Reveal({
    correct,
    submitted,
    children,
    explanation,
}: RevealProps) {
    return (
        <>
            {submitted && correct && <CorrectText />}

            {submitted && !correct && (
                <Stack spacing={1} marginLeft={2}>
                    <WrongText />
                    {children}
                    <Typography>{CORRECT_TEXT}</Typography>
                </Stack>
            )}

            <Explanation explanation={explanation} />
        </>
    );
}
