import { Alert } from "@mui/material";
import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { computeProgress } from "@teyalite/hackbio-common/dist/compute-progress";
import { CourseDetailsResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { ReactComponent as CupIcon } from "../../assets/icons/cup.svg";
import BorderBlueButton from "../../components/BorderBlueButton";
import CLink from "../../components/CLink";
import ModuleItem, { CompletedButton } from "../../components/ModuleItem";
import { HALL_OF_FAME } from "../../constants";
import { fetchCourseDetailsCreator } from "../../redux/classroom";
import { AppState } from "../../redux/store";
import { theme } from "../../utils/theme";
import ShareButton from "./ShareButton";

type Props = PropsFromRedux;

class MyCourse extends Component<Props> {
  // Link that point to the first uncompleted submodule
  resumeLink = () => {
    let link = "";

    this.props.modules.forEach((m) =>
      m.submodules.forEach((sm) => {
        if (!sm.completed && link === "")
          link =
            "/classroom/" + this.props.id + "/" + m.id + "?submodule=" + sm.id;
      })
    );

    return link;
  };

  displayButton = (
    status: "enrolled" | "trial" | "not-enrolled",
    fullLink: string
  ) => {
    if (status === "not-enrolled") {
      return (
        <MuiLink
          href={fullLink}
          component="a"
          target="_blank"
          rel="noreferrer"
          color="inherit"
          underline="none"
        >
          <BorderBlueButton>Start Now</BorderBlueButton>
        </MuiLink>
      );
    }

    const link = this.resumeLink();

    if (!link) {
      return <CompletedButton />;
    }

    return (
      <BorderBlueButton LinkComponent={CLink} href={link}>
        Resume →
      </BorderBlueButton>
    );
  };

  render() {
    const {
      title,
      xp,
      totalSubmodules,
      completedSubmodules,
      status,
      modules,
      fullLink,
      badges,
    } = this.props;

    const progress = computeProgress(completedSubmodules, totalSubmodules);

    return (
      <Stack component={Container} sx={styles.root} spacing={{ xs: 6, sm: 8 }}>
        <Stack spacing={4}>
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>

          <Stack
            className="bold-border"
            p={3}
            sx={{ background: theme.palette.warning.light }}
            spacing={2.5}
          >
            <Stack spacing={1}>
              <Typography fontWeight="bold" className="text-gray">
                HackBio Course
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {title}
              </Typography>
            </Stack>

            {status === "trial" && (
              <Stack spacing={2}>
                <Alert severity="warning">
                  You're on trial, enroll to get access to full content
                </Alert>

                <MuiLink
                  href={fullLink}
                  sx={{ alignSelf: "flex-start" }}
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                >
                  <BorderBlueButton size="small">Enroll Now</BorderBlueButton>
                </MuiLink>
              </Stack>
            )}
            <Stack direction="row" spacing={{ xs: 3, sm: 5 }}>
              <Typography>
                Progress: <strong className="text-green">{progress}%</strong>
              </Typography>
              <Typography>
                Points: <strong className="text-xp">{xp} XP</strong>
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              {this.displayButton(status, fullLink)}
              <ShareButton link={fullLink} />
            </Stack>
          </Stack>
        </Stack>

        {modules.map((module, index) => (
          <ModuleItem
            key={module.id}
            index={index + 1}
            status={status}
            module={module}
            fullLink={fullLink}
          />
        ))}

        <Stack
          sx={styles.hallOfFame}
          className="bold-border"
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
        >
          <CupIcon width={64} />
          <Stack spacing={{ xs: 3, sm: 2 }}>
            <Typography variant="h5" fontWeight="bold">
              See what awaits you!
            </Typography>
            <Stack component="ul" pl={2.5} spacing={1}>
              <Typography component="li">Certificate in {title}</Typography>
              {badges.length > 0 && (
                <Typography component="li">
                  Badge in {badges.join(", ")}
                </Typography>
              )}
            </Stack>
            <CLink href={HALL_OF_FAME} sx={styles.hallOfFameLink}>
              <BorderBlueButton sx={styles.hallOfFameButton}>
                Hall of fame
              </BorderBlueButton>
            </CLink>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

const styles = {
  root: { pt: { xs: 3, sm: 4 }, pb: 3 },
  hallOfFame: { background: theme.palette.warning.light, p: 3 },
  hallOfFameLink: {
    alignSelf: "flex-start",
  },
  hallOfFameButton: {
    textTransform: "none",
  },
};

const mapDispatchToProps = {
  fetch: fetchCourseDetailsCreator,
};

function mapStateToProps(state: AppState) {
  return {
    ...(state.classroom.details.course as CourseDetailsResponse),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MyCourse);
