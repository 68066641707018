import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { computeProgress } from "@teyalite/hackbio-common/dist/compute-progress";
import LockIcon from "@mui/icons-material/Lock";
import {
  CourseModuleResponse,
  CourseSubmoduleResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { generateClassroomPath } from "../utils";
import { theme } from "../utils/theme";
import BorderBlueButton from "./BorderBlueButton";
import CLink from "./CLink";
import LinearProgressBar from "./LinearProgressBar";

interface Props {
  fullLink: string;
  status: "trial" | "enrolled" | "not-enrolled";
  index: number;
  module: CourseModuleResponse;
}

export default function ModuleItem({ status, fullLink, index, module }: Props) {
  const {
    submodules,
    title,
    courseId,
    id,
    xp,
    completedSubmodules,
    totalSubmodules,
  } = module;

  const disabled = status === "not-enrolled";

  const submoduleLink = (sId: number) =>
    generateClassroomPath({ courseId, moduleId: id, submoduleId: sId });

  const progress = computeProgress(completedSubmodules, totalSubmodules);

  const resumeLink = () => {
    let link = "";

    submodules.forEach((s) => {
      if (!s.completed && link === "") link = submoduleLink(s.id);
    });

    return link;
  };

  const displayButton = () => {
    if (status === "not-enrolled") {
      return (
        <MuiLink
          href={fullLink}
          component="a"
          target="_blank"
          rel="noreferrer"
          color="inherit"
          underline="none"
        >
          <BorderBlueButton>Start Now</BorderBlueButton>
        </MuiLink>
      );
    }

    const link = resumeLink();

    if (progress === 100 || !link) {
      return <CompletedButton />;
    }

    if (module.submodules[0].isLocked) {
      return (
        <MuiLink
          href={fullLink}
          component="a"
          target="_blank"
          rel="noreferrer"
          color="inherit"
          underline="none"
        >
          <BorderBlueButton>Start Now</BorderBlueButton>
        </MuiLink>
      );
    }

    return (
      <BorderBlueButton
        sx={styles.resumeButton}
        LinkComponent={CLink}
        href={link}
        disabled={disabled}
      >
        Resume
      </BorderBlueButton>
    );
  };

  return (
    <Stack sx={styles.root} className="bold-border">
      <Stack p={3} spacing={2.5}>
        <Typography variant="h6" fontWeight="bold">
          Module {index}: {title}
        </Typography>

        <Stack component="ul" pl={2} spacing={1}>
          {submodules.map((submodule, index) => (
            <Submodule
              disabled={disabled || submodule.isLocked}
              isTrial={status === "trial"}
              key={submodule.id}
              submoduleLink={submoduleLink}
              submodule={submodule}
            />
          ))}
        </Stack>
      </Stack>

      <Divider />

      <Stack
        py={1.5}
        px={2}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1.5, sm: 3 }}
      >
        <LinearProgressBar
          value={progress}
          style={{ maxWidth: "400px", width: "100%" }}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ flexGrow: 1 }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
        >
          <strong className="text-xp" style={{ minWidth: 80 }}>
            {xp} XP
          </strong>

          {displayButton()}
        </Stack>
      </Stack>
    </Stack>
  );
}

export function CompletedButton() {
  return (
    <BorderBlueButton sx={styles.completedButton} disabled>
      Completed
    </BorderBlueButton>
  );
}

function Submodule({
  disabled,
  isTrial,
  submodule,
  submoduleLink,
}: {
  disabled: boolean;
  submodule: CourseSubmoduleResponse;
  isTrial: boolean;
  submoduleLink: (id: number) => string;
}) {
  const Icon = <CheckCircleIcon color="primary" sx={styles.submodule.icon} />;

  if (disabled) {
    return (
      <Typography component="li">
        <MuiLink sx={styles.submodule.muiLink} underline="none">
          {submodule.title}

          {submodule.completed && Icon}
          {submodule.isLocked && isTrial && (
            <LockIcon style={{ fontSize: 16, marginLeft: 2 }} />
          )}
        </MuiLink>
      </Typography>
    );
  }

  return (
    <Typography component="li">
      <CLink
        href={submoduleLink(submodule.id)}
        sx={styles.submodule.muiLink}
        underline="none"
      >
        {submodule.title}
        {submodule.completed && Icon}
      </CLink>
    </Typography>
  );
}

const styles = {
  root: { background: theme.palette.warning.light },
  submodule: {
    muiLink: {
      color: "black",
      "&:hover": {
        fontWeight: "bold",
      },
      alignItems: "center",
      display: "flex",
    },
    icon: { ml: 2, width: 20 },
  },
  completedButton: {
    // backgroundColor: "#6fe4a0",
    // "&:hover": { backgroundColor: "#6fe4a0" },
  },
  resumeButton: {
    background: "#BDDCFB",
    color: "black",
    px: 3,
    textTransform: "none",
    display: { xs: "none", sm: "flex" },
    fontWeight: "bold",
  },
};
