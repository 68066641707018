import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import BadgeItem from "../components/BadgeItem";
import CourseCertificate from "../components/Certificate";
import Loading from "../components/Loading";
import Profile from "../components/Profile";
import { fetchHallOfFameCreator } from "../redux/account";
import { AppState } from "../redux/store";
import { getRequest } from "../utils/http";

type Props = PropsFromRedux;

class HallOfFame extends Component<Props> {
    componentDidMount(): void {
        const { isLoading, badges, certificates } = this.props.store;

        if (!isLoading && badges.length === 0 && certificates.length === 0) {
            this.fetchHallOfFame();
        }
    }

    fetchHallOfFame = async () => {
        const { fetch } = this.props;

        fetch({ ...this.props.store, hasFailed: false, isLoading: true });

        try {
            const data = await getRequest("/account/hall-of-fame");

            fetch({ ...this.props.store, isLoading: false, ...data });
        } catch (error: any) {
            fetch({ ...this.props.store, hasFailed: true, isLoading: false });
        }
    };

    render() {
        const { certificates, badges, isLoading, hasFailed } = this.props.store;

        return (
            <Stack
                component={Container}
                pt={{ xs: 3, sm: 4 }}
                pb={3}
                spacing={{ xs: 6, sm: 6 }}
            >
                <Profile title="Hall of fame" />
                {isLoading || hasFailed ? (
                    <Stack py={1}>
                        <Loading
                            failed={hasFailed}
                            spacing={1}
                            onRetry={this.fetchHallOfFame}
                        />
                    </Stack>
                ) : (
                    <>
                        <Stack spacing={3}>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                alignSelf="center"
                            >
                                My Biobadges
                            </Typography>
                            <Grid
                                container
                                sx={{}}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 16, lg: 20 }}
                            >
                                {badges.map((badge, index) => (
                                    <Grid xs={2} sm={4} md={4} key={index}>
                                        <BadgeItem {...badge} key={badge.id} />
                                    </Grid>
                                ))}
                            </Grid>
                            {badges.length === 0 && (
                                <Typography textAlign="center">
                                    You don't have any badges
                                </Typography>
                            )}
                        </Stack>

                        <Stack spacing={4}>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                alignSelf="center"
                            >
                                My Certificates
                            </Typography>
                            {certificates.length > 0 && (
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 1, sm: 8, lg: 12 }}
                                >
                                    {certificates.map((course, index) => (
                                        <Grid xs={2} sm={4} md={4} key={index}>
                                            <CourseCertificate
                                                key={course.id}
                                                course={course}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            {certificates.length === 0 && (
                                <Typography textAlign="center">
                                    You don't have any certificates
                                </Typography>
                            )}
                        </Stack>
                    </>
                )}
            </Stack>
        );
    }
}

const mapDispatchToProps = {
    fetch: fetchHallOfFameCreator,
};

function mapStateToProps(state: AppState) {
    return { store: { ...state.account.hallOfFame } };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HallOfFame);
