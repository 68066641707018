/**
 * This item is displayed on big screen inside the board right to the content
 * Optimized
 */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { generateClassroomPath, getSubmoduleMeta } from "../../utils";
import CLink from "../CLink";
import { CourseSubmoduleResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import LockIcon from "@mui/icons-material/Lock";

export default function SubmoduleItem({
  submodule,
}: {
  submodule: CourseSubmoduleResponse;
}) {
  const meta = getSubmoduleMeta(submodule.type);

  const link = generateClassroomPath({
    courseId: submodule.courseId,
    moduleId: submodule.moduleId,
    submoduleId: submodule.id,
  });

  return (
    <Stack
      direction="row"
      sx={styles.root}
      component={CLink}
      href={link}
      underline="hover"
      color="inherit"
      spacing={1}
    >
      <img
        src={meta.src}
        alt="HackBio Learning Section"
        style={{ width: 36 }}
      />

      <Typography sx={{ flex: 1 }} fontWeight="bold">
        {meta.label}: {submodule.title}
      </Typography>

      {submodule.completed && (
        <CheckCircleIcon color="primary" sx={{ width: "16px" }} />
      )}
      
      {submodule.isLocked && (
        <LockIcon style={{ fontSize: 16, marginLeft: 2 }} />
      )}
    </Stack>
  );
}

const styles = {
  root: {
    bgcolor: "#fff",
    alignItems: "center",
    py: 1,
    px: 0.5,
  },
};
