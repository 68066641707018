import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";

type Props = {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    label?: string;
};

export default function QuestionTestBlueInput({
    placeholder,
    onChange,
    value,
    disabled = false,
    label,
}: Props) {
    return (
        <TextField
            label={label ?? placeholder}
            variant="filled"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            color="info"
            size="small"
            disabled={disabled}
            autoComplete="off"
            sx={{
                width: "100%",
                "&.MuiTextField-root": {
                    background: "rgb(47 128 237 / 11%)",
                    borderLeft: "3px solid #6F71E0",
                    borderBottom: "none",
                },
                "& :focus": {},
            }}
        />
    );
}
