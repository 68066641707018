import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { computeProgress } from "@teyalite/hackbio-common/dist/compute-progress";
import { CourseDetailsResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ModuleType } from "@teyalite/hackbio-common/dist/types/module-type.enum";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import { Component, createRef, RefObject } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import CourseOutline from "../../components/classroom/CourseOutline";
import SubmoduleContent from "../../components/classroom/SubmoduleContent";
import SubmoduleItem from "../../components/classroom/SubmoduleItem";
import CLink from "../../components/CLink";
import ModuleNotFound from "../../components/ModuleNotFound";
import { resetAccountMyCoursesStateCreator } from "../../redux/account";
import { updateSubmoduleCreator } from "../../redux/classroom";
import { resetHomeCoursesStateCreator } from "../../redux/home";
import { AppState } from "../../redux/store";
import { generateClassroomPath, getSubmoduleMeta } from "../../utils";
import { getRequest } from "../../utils/http";

type Props = { submoduleIndex: number; moduleIndex: number } & PropsFromRedux;

type State = {
  isMarkingAsComplete: boolean;
};

class CourseModuleComp extends Component<Props, State> {
  private nextButtonRef: RefObject<HTMLAnchorElement>;
  private projectSubmitRef: RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isMarkingAsComplete: false,
    };

    this.nextButtonRef = createRef<HTMLAnchorElement>();
    this.projectSubmitRef = createRef();
  }

  goPrev = (): string => {
    const { id, moduleIndex, modules, submoduleIndex } = this.props;

    if (submoduleIndex > 0) {
      return generateClassroomPath({
        courseId: id,
        moduleId: modules[moduleIndex].id,
        submoduleId: modules[moduleIndex].submodules[submoduleIndex - 1].id,
      });
    }

    if (moduleIndex > 0) {
      const module = modules[moduleIndex - 1];

      return generateClassroomPath({
        courseId: id,
        moduleId: module.id,
        submoduleId: module.submodules[module.submodules.length - 1].id,
      });
    }

    return "";
  };

  goNext = (): string => {
    const { id, moduleIndex, modules, submoduleIndex } = this.props;

    if (submoduleIndex < modules[moduleIndex].submodules.length - 1) {
      return generateClassroomPath({
        courseId: id,
        moduleId: modules[moduleIndex].id,
        submoduleId: modules[moduleIndex].submodules[submoduleIndex + 1].id,
      });
    }

    if (moduleIndex < modules.length - 1) {
      const module = modules[moduleIndex + 1];

      return generateClassroomPath({
        courseId: id,
        moduleId: module.id,
        submoduleId: module.submodules[0].id,
      });
    }

    return "";
  };

  next = async () => {
    const { moduleIndex, modules, submoduleIndex } = this.props;
    const module = modules[moduleIndex];
    const submodule = module.submodules[submoduleIndex];

    this.setState({ isMarkingAsComplete: true });

    try {
      const { xp } = await getRequest<{ xp: number }>(
        `/course/complete?courseId=${submodule.courseId}&moduleId=${submodule.moduleId}&submoduleId=${submodule.id}`
      );

      this.props.updateSubmodule({ moduleIndex, submoduleIndex, xp });

      this.setState({ isMarkingAsComplete: false });

      this.props.resetAccountMyCoursesState();
      this.props.resetHomeCoursesState();
    } catch (error: any) {
      // todo: fail messge
      this.setState({ isMarkingAsComplete: false });
    }
  };

  getNextModuleLink = (): string => {
    const { moduleIndex, modules } = this.props;

    if (moduleIndex < modules.length - 1) {
      return generateClassroomPath({
        courseId: modules[moduleIndex + 1].courseId,
        moduleId: modules[moduleIndex + 1].id,
        submoduleId: modules[moduleIndex + 1].submodules[0].id,
      });
    }

    return "";
  };

  handleSubmitProject = () => {
    try {
      this.projectSubmitRef.current.click();
    } catch (error) {}
  };

  render() {
    const { isMarkingAsComplete } = this.state;
    const { id, moduleIndex, modules, submoduleIndex, } = this.props;

    const classroomLink = generateClassroomPath({ courseId: id });
    const nextModuleLink = this.getNextModuleLink();

    const module = modules[moduleIndex];
    const submodule = module.submodules[submoduleIndex];
    const submoduleMeta = getSubmoduleMeta(submodule.type);

    const prevSubmodule = this.goPrev();
    const nextSubmodule = this.goNext();

    return (
      <Stack
        component={Container}
        sx={styles.root}
        spacing={{ xs: 2, sm: 4, md: 2 }}
      >
        <Stack spacing={2}>
          {/* Outline & Title */}
          <CourseOutline
            link={classroomLink}
            prev={prevSubmodule}
            next={nextSubmodule}
            ref={this.nextButtonRef}
          />

          <Stack spacing={1}>
            <Typography sx={{ color: "gray" }}>
              Module {moduleIndex + 1}: {module.title}
            </Typography>

            {module.type === ModuleType.Project && (
              <small style={{ color: "#ed6c02" }}>
                ⚠️ You must choose from the projects and submit only one
              </small>
            )}
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Stack
            sx={styles.submoduleContent}
            className="bold-border"
            direction="column"
          >
            {/* Submodule Content */}
            <Stack
              direction="row"
              spacing={2}
              pt={2}
              pb={0}
              px={{ xs: 1, sm: 2 }}
            >
              <Typography sx={{ flexGrow: 1, fontWeight: "bold" }}>
                {submoduleMeta.label}: {submodule.title}
              </Typography>
              {!submodule.isLocked && (
                <>
                  {submodule.type === SubmoduleType.Project ? (
                    <Button
                      variant="contained"
                      sx={styles.submitButtonBigScreen}
                      onClick={this.handleSubmitProject}
                      disableElevation
                    >
                      Submit Project
                    </Button>
                  ) : submodule.type === SubmoduleType.Test ? null : (
                    <LoadingButton
                      onClick={this.next}
                      sx={styles.completeButtonBigScreen}
                      size="small"
                      loadingPosition="start"
                      variant="contained"
                      color="warning"
                      disableElevation
                      startIcon={<CheckIcon />}
                      disabled={submodule.completed}
                      loading={isMarkingAsComplete}
                    >
                      Mark Completed
                    </LoadingButton>
                  )}
                </>
              )}
            </Stack>

            <Stack
              bgcolor="white"
              flexGrow={1}
              borderRadius={{ xs: 1, sm: 1.5 }}
              m={{ xs: 1, sm: 1.5 }}
            >
              <SubmoduleContent
                key={submodule.id}
                submodule={submodule}
                submodules={module.submodules}
                moduleType={module.type}
                ref={this.projectSubmitRef}
              />
            </Stack>
          </Stack>

          <Stack sx={styles.moduleOverview} spacing={1}>
            {/* Module Overview */}
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" fontWeight="bold">
                Module {moduleIndex + 1}:
              </Typography>

              <Stack direction="row" spacing={2}>
                <Typography className="text-green" fontWeight="bold">
                  ⌛️{" "}
                  {computeProgress(
                    module.completedSubmodules,
                    module.totalSubmodules
                  )}
                  %
                </Typography>
                <Typography className="text-xp" fontWeight="bold">
                  🚀 {module.xp} XP
                </Typography>
              </Stack>
            </Stack>

            {module.submodules.map((s) => (
              <SubmoduleItem submodule={s} key={s.id} />
            ))}

            <Stack position="absolute" bottom={0} left={0} right={0} p={2}>
              <Button
                variant="contained"
                color="warning"
                disableElevation
                sx={{
                  alignSelf: "flex-start",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                LinkComponent={CLink}
                href={nextModuleLink}
                disabled={!Boolean(nextModuleLink)}
              >
                Next Module
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {!submodule.isLocked && (
          <>
            {submodule.type === SubmoduleType.Project ? (
              <Button
                variant="contained"
                sx={styles.submitButtonSmallScreen}
                onClick={this.handleSubmitProject}
                disableElevation
              >
                Submission Project
              </Button>
            ) : submodule.type === SubmoduleType.Test ? null : (
              <LoadingButton
                onClick={this.next}
                sx={styles.completeButtonSmallScreen}
                disableElevation
                variant="contained"
                color="warning"
                loadingPosition="start"
                startIcon={<CheckIcon />}
                loading={isMarkingAsComplete}
                disabled={submodule.completed}
              >
                Mark Completed
              </LoadingButton>
            )}
          </>
        )}

        <Stack
          display={{ md: "none", xs: "flex" }}
          justifyContent="space-evenly"
          direction="row"
        >
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              mt: 4,
            }}
            variant="contained"
            disableElevation
            color="warning"
            size="small"
            LinkComponent={CLink}
            href={prevSubmodule}
            disabled={!Boolean(prevSubmodule)}
          >
            Previous
          </Button>

          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              mt: 4,
            }}
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            disableElevation
            color="warning"
            size="small"
            LinkComponent={CLink}
            href={nextSubmodule}
            disabled={!Boolean(nextSubmodule)}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    );
  }
}

function CourseModule(props: PropsFromRedux) {
  const { modules, status } = props;

  const { moduleId, courseId } = useParams();
  const [searchParams] = useSearchParams();

  const id = Number(moduleId);
  const submoduleId = Number(searchParams.get("submodule"));

  const moduleIndex = modules.findIndex((mod) => mod.id === id);

  const module = moduleIndex >= 0 ? modules[moduleIndex] : undefined;
  const index = module?.submodules.findIndex((s) => s.id === submoduleId) ?? -1;

  if (status === "not-enrolled") {
    return <Navigate to={"/classroom/" + courseId} />;
  }

  if (!module || index < 0) {
    return <ModuleNotFound />;
  }

  return (
    <CourseModuleComp
      submoduleIndex={index}
      moduleIndex={moduleIndex}
      {...props}
    />
  );
}

const styles = {
  root: { pt: { xs: 3, sm: 4 }, pb: 3 },
  completeButtonSmallScreen: {
    display: { xs: "flex", md: "none" },
    textTransform: "none",
    color: "black",
    fontWeight: "bold",
    alignSelf: "center",
    px: 2,
  },
  submitButtonSmallScreen: {
    display: { xs: "flex", md: "none" },
    textTransform: "none",
    fontWeight: "bold",
    color: "white",
    alignSelf: "center",
  },
  submoduleContent: {
    width: {
      xs: "100%",
      md: "calc(100% - 320px)",
      lg: "calc(100% - 350px)",
    },
    minHeight: "100px",
    bgcolor: "rgba(196, 196, 196, 0.2)",
  },
  moduleOverview: {
    border: "3px solid #000000",
    borderRadius: "5px",
    width: { xs: "100%", md: "310px", lg: "340px" },
    p: 2,
    alignSelf: "flex-start",
    background: "rgba(196, 196, 196, 0.2)",
    display: { xs: "none", md: "flex" },
    maxHeight: "600px",
    minHeight: "300px",
    overflowY: "auto",
    pb: "80px",
    position: "relative",
  },
  completeButtonBigScreen: {
    display: { xs: "none", sm: "flex" },
    textTransform: "none",
    color: "black",
    fontWeight: "bold",
  },
  submitButtonBigScreen: {
    display: { xs: "none", sm: "flex" },
    textTransform: "none",
    fontWeight: "bold",
    color: "white",
  },
};

const mapDispatchToProps = {
  updateSubmodule: updateSubmoduleCreator,
  resetAccountMyCoursesState: resetAccountMyCoursesStateCreator,
  resetHomeCoursesState: resetHomeCoursesStateCreator,
};

function mapStateToProps(state: AppState) {
  return {
    ...(state.classroom.details.course as CourseDetailsResponse),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CourseModule);
