import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import React, { PropsWithChildren } from "react";

export default function GreenButton({
    children,
    sx,
}: PropsWithChildren & { sx?: SxProps }) {
    return (
        <Button
            variant="contained"
            sx={{
                color: "white",
                textTransform: "none",
                py: 1,
                px: 3,
                fontWeight: "bold",
                ...sx,
            }}
            disableElevation
        >
            {children}
        </Button>
    );
}
