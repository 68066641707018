import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { isUUID } from "class-validator";
import { StatusCodes } from "http-status-codes";
import { Component } from "react";
import { useSearchParams } from "react-router-dom";
import Verified from "../../assets/icons/verified-blue.svg";
import { AuthContext } from "../../auth.context";
import BackdropLoading from "../../components/auth/BackdropLoading";
import CountDown from "../../components/auth/CountDown";
import AuthLayout from "../../components/auth/Layout";
import { AuthContextType, User } from "../../types";
import { postRequest } from "../../utils/http";
import NotFound from "../NotFound";

type PageProps = {
    code: string;
    codeId: string;
};

type State = {
    isFirstLoading: boolean;
    status: "success" | "invalid" | "error";
    name: string;
};

class Confirm extends Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            isFirstLoading: true,
            status: "invalid",
            name: "",
        };
    }

    static contextType = AuthContext;

    async componentDidMount() {
        const { code, codeId } = this.props;

        const { setUser } = this.context as AuthContextType;

        try {
            const user = await postRequest<User>("/auth/confirmation", {
                code,
                codeId,
            });

            setUser(user);

            this.setState({
                isFirstLoading: false,
                status: "success",
                name: user.name,
            });
        } catch (error: any) {
            if (
                error.response &&
                error.response.status === StatusCodes.NOT_FOUND
            ) {
                return this.setState({
                    isFirstLoading: false,
                    status: "invalid",
                });
            }

            this.setState({ isFirstLoading: false, status: "error" });
        }
    }

    /**
     * Reload in case there is a 500 error
     */
    refresh = () => {
        window.location.reload();
    };

    render() {
        const { isFirstLoading, status, name } = this.state;

        if (isFirstLoading) {
            return (
                <Box className="bgcolor-blue" width="100%" height="100%">
                    <BackdropLoading open />
                </Box>
            );
        }

        if (status === "success") {
            return (
                <AuthLayout sx={{ alignItems: "center", textAlign: "center" }}>
                    <Box>
                        <img
                            src={Verified}
                            alt="Verified"
                            width={24}
                            height={24}
                        />
                    </Box>
                    <Typography variant="h5" fontWeight="bold">
                        Welcome {name}, you have been verified.
                    </Typography>
                    <Typography className="text-gray">
                        You will be redirected to the main page in screen in{" "}
                        <CountDown seconds={5} redirect="/" /> seconds
                    </Typography>
                </AuthLayout>
            );
        }

        if (status === "invalid") {
            return (
                <AuthLayout sx={{ alignItems: "center", textAlign: "center" }}>
                    <BackdropLoading open={false} />
                    <Typography variant="h5" fontWeight="bold">
                        There is a small problem
                    </Typography>
                    <Typography variant="h6">
                        This confirmation link is invalid. Maybe you have
                        already used it?
                    </Typography>
                </AuthLayout>
            );
        }

        return (
            <AuthLayout>
                <Typography
                    variant="h1"
                    sx={{ my: 3, fontSize: "2.5rem", fontWeight: "bold" }}
                >
                    500:
                    <Box
                        component={"br"}
                        sx={{ display: { xs: "block", sm: "none" } }}
                    />
                    {" Woops!"}
                </Typography>
                <Typography variant="h5">Something went wrong</Typography>
                <Button
                    color="info"
                    variant="contained"
                    disableElevation
                    onClick={this.refresh}
                >
                    Please try again
                </Button>
            </AuthLayout>
        );
    }
}

export default function Confirmation() {
    const [searchParams] = useSearchParams();

    const [code, id] = [searchParams.get("code"), searchParams.get("id")];

    if (!code || code.trim().length === 0 || !isUUID(id)) {
        return <NotFound />;
    }

    return <Confirm code={code} codeId={id as string} />;
}
