import styled from "@emotion/styled";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import {
    IMAGE_ALLOWED_MIME_TYPES,
    IMAGE_ALLOWED_TYPES,
    IMAGE_SIZE_LIMIT,
} from "@teyalite/hackbio-common/dist/constants";
import { fileExtension } from "@teyalite/hackbio-common/dist/file-helper";
import { StatusCodes } from "http-status-codes";
import { ChangeEvent, RefObject, createRef, useContext, useState } from "react";
import DEFAULT_USER_AVATAR from "../assets/icons/default-avatar.svg";
import { AuthContext } from "../auth.context";
import {
    CONNECTION_FAILED,
    IMAGE_PROCESS_FAIL_MESSAGE,
    INVALID_TYPE_MESSAGE,
    SIZE_LIMIT_MESSAGE,
} from "../constants";
import { AuthContextType } from "../types";
import { extractResponseMessage } from "../utils";
import { patchRequest } from "../utils/http";
import { theme } from "../utils/theme";
import BackdropLoading from "./auth/BackdropLoading";

export default function Profile({ title }: { title: string }) {
    const context = useContext(AuthContext) as AuthContextType;
    const user = context.user!;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [image, setImage] = useState<string | null>(user.image);
    const [imageFile, setImageFile] = useState<File | null>(null);

    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const open = Boolean(anchorEl);
    const input: RefObject<HTMLInputElement> = createRef();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const uploadPhoto = () => {
        if (input !== null && input.current) {
            input.current.click();
        }
        handleClose();
    };

    const removePhoto = () => {
        // setShowSave(true);
        setImage(null);
        setImageFile(null);
        handleClose();

        // Clear input
        input.current!.type = "text";
        input.current!.type = "file";
    };

    const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length < 1) {
            return;
        }

        const file = event.target.files[0];

        if (
            !IMAGE_ALLOWED_TYPES.includes(fileExtension(file.name)) ||
            !IMAGE_ALLOWED_MIME_TYPES.includes(file.type)
        ) {
            window.alert(INVALID_TYPE_MESSAGE);
            return;
        }

        if (file.size > IMAGE_SIZE_LIMIT) {
            window.alert(SIZE_LIMIT_MESSAGE);
            return;
        }

        const reader = new FileReader();

        // When successfylly loaded
        reader.onload = (event: ProgressEvent<FileReader>) => {
            setImageFile(file);
            setImage(reader.result as string);
        };

        // When error occurs
        reader.onerror = (event: ProgressEvent<FileReader>) => {
            window.alert(IMAGE_PROCESS_FAIL_MESSAGE);
        };

        reader.readAsDataURL(file);
    };

    const saveChanges = async () => {
        setLoading(true);
        setErrorMessage("");

        const fd = new FormData();

        if (imageFile) {
            fd.append("image", imageFile);
        }

        try {
            const { image } = await patchRequest("/account/profile-photo", fd);
            context.setUser({ ...user, image });
            setLoading(false);
            setImageFile(null);
            setImage(image);
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                error.response.data &&
                error.response.status !== StatusCodes.INTERNAL_SERVER_ERROR
            ) {
                message = extractResponseMessage(error.response.data.message);
            }

            setLoading(false);
            setErrorMessage(message);
        }
    };

    return (
        <Stack alignItems="center" spacing={{ xs: 4, sm: 6 }}>
            <BackdropLoading open={isLoading} />
            <Stack spacing={2}>
                <Stack position="relative">
                    <input
                        type="file"
                        ref={input}
                        hidden
                        onChange={handleFileInput}
                    />

                    <Box
                        component="img"
                        src={image ?? DEFAULT_USER_AVATAR}
                        alt="User Account"
                        className="bold-border"
                        sx={Styles.avatar}
                    />

                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={Styles.editButton}
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        Edit
                    </Button>

                    <StyledMenu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={uploadPhoto} disableRipple>
                            <AddAPhotoIcon />
                            Upload photo
                        </MenuItem>

                        {image !== null && (
                            <MenuItem onClick={removePhoto} disableRipple>
                                <RemoveIcon />
                                Remove photo
                            </MenuItem>
                        )}
                    </StyledMenu>
                </Stack>

                {errorMessage.length > 0 && (
                    <Typography
                        color={theme.palette.error.main}
                        textAlign="center"
                    >
                        {errorMessage}
                    </Typography>
                )}

                {user.image !== image && (
                    <Button
                        disableElevation
                        onClick={saveChanges}
                        variant="contained"
                        sx={Styles.savePhoto}
                    >
                        Save photo
                    </Button>
                )}
            </Stack>

            <Stack spacing={3} alignItems="center" textAlign="center">
                <Typography variant="h4" fontWeight="bold" sx={Styles.name}>
                    {user.name}
                </Typography>
                <Typography variant="h5">{title}</Typography>
            </Stack>
        </Stack>
    );
}

const Styles = {
    avatar: {
        width: { xs: "150px", sm: "200px" },
        height: { xs: "150px", sm: "200px" },
        borderRadius: { xs: "75px", sm: "100px" },
        objectFit: "cover",
    },
    editButton: {
        textTransform: "none",
        position: "absolute",
        background: "white",
        left: 0,
        bottom: 0,
        "&:hover": {
            background: "white",
        },
    },
    savePhoto: {
        textTransform: "none",
        fontWeight: "bold",
        color: "white",
    },
    name: { fontSize: { xs: "1.75rem", sm: "2.125rem" } },
};

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(() => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));
