import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Stack, Typography } from "@mui/material";
import { arrayEquals } from "@teyalite/hackbio-common/dist/array-equals";
import {
    ReorderListAnswerReveal,
    TestSubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { PropsWithChildren } from "react";
import { ReorderListItemType, TestStatus } from "../../../types";
import { theme } from "../../../utils/theme";
import SubmitButton from "./SubmitButton";
import { FeedbackSurvey, Reveal, Thanks, WrongText } from "./Verdict";

type Props = {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    onSubmit: () => void;
    submitted: boolean;
    items: ReorderListItemType[];
    setItems: (items: ReorderListItemType[]) => void;
};

export default function DragDropReorderList({
    test,
    submitted,
    onSubmit,
    status,
    items,
    setItems,
}: Props) {
    let startIndex: number | null = null,
        enterIndex: number | null = null;

    const handleDragEnd = () => {
        if (startIndex === null || enterIndex === null) return;

        const tmpItems = items.slice();

        // Remove the dragged item from the items
        const dragItem = tmpItems.splice(startIndex, 1)[0];

        tmpItems.splice(enterIndex, 0, dragItem);

        setItems(tmpItems);

        startIndex = null;
        enterIndex = null;
    };

    if (test.answer && test.xp === 0) {
        const ans = test.answer as ReorderListAnswerReveal;

        const ITEMS =
            status === "failed" && submitted
                ? items
                : ans.list.map((txt, index) => ({ txt, key: index }));

        return (
            <Stack flexGrow={1} spacing={3}>
                <FeedbackSurvey />

                <Typography fontWeight="bold">{test.question}</Typography>

                <Stack spacing={1} sx={styles.dragContainer}>
                    {ITEMS.map((item, index) => (
                        <DragItem
                            key={item.key}
                            handleDragEnd={handleDragEnd}
                            handleDragEnter={() => {
                                enterIndex = index;
                            }}
                            handleDragStart={() => {
                                startIndex = index;
                            }}
                        >
                            {item.txt}
                        </DragItem>
                    ))}
                </Stack>

                <Thanks />
            </Stack>
        );
    }

    if (test.answer) {
        const ans = test.answer as ReorderListAnswerReveal;

        const ITEMS =
            status === "failed" && submitted
                ? items
                : ans.list.map((txt, index) => ({ txt, key: index }));

        return (
            <Stack flexGrow={1} spacing={3}>
                <Typography fontWeight="bold">{test.question}</Typography>

                <Stack spacing={1} sx={styles.dragContainer}>
                    {ITEMS.map((item, index) => (
                        <DragItem
                            key={item.key}
                            handleDragEnd={handleDragEnd}
                            handleDragEnter={() => {
                                enterIndex = index;
                            }}
                            handleDragStart={() => {
                                startIndex = index;
                            }}
                        >
                            {item.txt}
                        </DragItem>
                    ))}
                </Stack>

                <Reveal
                    submitted={submitted}
                    explanation={ans.explanation}
                    correct={arrayEquals<string>(
                        items.map((item) => item.txt),
                        ans.list
                    )}
                >
                    <Stack component="ol" marginLeft={2}>
                        {ans.list.map((txt, index) => (
                            <Typography
                                component="li"
                                key={index}
                                marginLeft={3}
                            >
                                {txt}
                            </Typography>
                        ))}
                    </Stack>
                </Reveal>
            </Stack>
        );
    }

    return (
        <Stack flexGrow={1} spacing={3}>
            {test.xp === 0 && <FeedbackSurvey />}

            <Typography fontWeight="bold">{test.question}</Typography>

            <Stack spacing={1} sx={styles.dragContainer}>
                {items.map((item, index) => (
                    <DragItem
                        key={item.key}
                        draggable
                        handleDragEnd={handleDragEnd}
                        handleDragEnter={() => {
                            enterIndex = index;
                        }}
                        handleDragStart={() => {
                            startIndex = index;
                        }}
                    >
                        {item.txt}
                    </DragItem>
                ))}
            </Stack>

            {status === "failed" && <WrongText />}

            <SubmitButton onClick={onSubmit} loading={status === "loading"}>
                Submit Answer
            </SubmitButton>
        </Stack>
    );
}

type DragItemProps = PropsWithChildren & {
    handleDragStart: () => void;
    handleDragEnter: () => void;
    handleDragEnd: () => void;
    draggable?: boolean;
};

function DragItem({
    handleDragEnter,
    handleDragStart,
    handleDragEnd,
    children,
    draggable = false,
}: DragItemProps) {
    return (
        <Stack
            direction="row"
            spacing={{ xs: 0.5, sm: 0.75, md: 1 }}
            sx={styles.dragItem}
            component="div"
            draggable={draggable}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragEnter={handleDragEnter}
            onDragOver={(e) => e.preventDefault()}
        >
            <DragIndicatorIcon sx={styles.dragIcon} />
            <Typography>{children}</Typography>
        </Stack>
    );
}

const styles = {
    dragContainer: {
        pb: 0.5,
    },
    dragItem: {
        background: theme.palette.warning.light,
        p: 1,
        border: "solid 1px gray",
        borderRadius: 1,
        fontWeight: "bold",
        alignItems: "center",
    },
    dragIcon: {
        fontSize: { xs: 20, sm: 20, md: 24 },
        color: "gray",
    },
};
