import { Stack, Typography } from "@mui/material";
import { ReactComponent as HBLogo } from "../assets/icons/hackbio.svg";
import CLink from "./CLink";

type Props = {
    width?: number;
    height?: number;
    color?: string;
    fontWeight?: string;
};

export default function Logo(props: Props) {
    const { width, height, color, fontWeight } = props;

    return (
        <Stack
            component={CLink}
            href="/"
            spacing={2}
            direction="row"
            sx={{
                flex: 0,
                textDecoration: "none",
                alignItems: "center",
                justifyContent: { xs: "center", md: "start" },
            }}
        >
            <HBLogo width={width ?? 24} height={height ?? 24} />
            <Typography
                variant="h5"
                sx={{ color: color ?? "white", fontWeight, ml: 2, mt: 2 }}
            >
                HackBio
            </Typography>
        </Stack>
    );
}
