import React, { useState } from "react";
import { NavBarItemType } from "../../types";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Stack, SxProps, Typography } from "@mui/material";
import { theme } from "../../utils/theme";
import CLink from "../CLink";

const styles: SxProps = {
    fontWeight: 600,
    width: "100%",
    my: 1,
    color: "black",
    textTransform: "none",
    "&:hover": {
        color: theme.palette.primary.main,
        background: "inherit",
    },
    "& .MuiButton-text": {
        bgcolor: "green",
    },
};

export default function DrawerNavItem({
    list,
    label,
    isExternal,
    link,
    onClick,
    afterClick,
}: NavBarItemType) {
    const [collapse, setCollapse] = useState(false);

    const handleClick = () => {
        setCollapse(!collapse);
        if (afterClick) afterClick();
    };

    if (list) {
        /**
         * Has a list of Child
         */
        return (
            <div style={{ width: "100%" }}>
                <Button
                    id="demo-customized-button"
                    aria-controls={
                        collapse ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={collapse ? "true" : undefined}
                    variant="text"
                    disableElevation
                    size="large"
                    sx={styles}
                    onClick={() => {
                        setCollapse(!collapse);
                    }}
                >
                    <Stack width="100%" direction="row" spacing={2}>
                        <Typography component="span" fontWeight="bold">
                            {label}
                        </Typography>

                        {typeof label !== "string" ? null : collapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </Stack>
                </Button>
                <Collapse in={collapse} timeout="auto" unmountOnExit>
                    <Stack
                        direction="column"
                        ml={6}
                        alignItems={"flex-start"}
                        flexGrow={1}
                    >
                        {list.map((data, index) => (
                            <DrawerNavItem
                                {...data}
                                key={"nested" + index}
                                afterClick={() => {
                                    handleClick();
                                    if (data.afterClick) data.afterClick();
                                }}
                            />
                        ))}
                    </Stack>
                </Collapse>
            </div>
        );
    }

    if (isExternal) {
        return (
            <div style={{ width: "100%" }}>
                <Button
                    LinkComponent={CLink}
                    href={link as string}
                    target="_blank"
                    rel="noreferrer"
                    key={link as string}
                    sx={styles}
                    onClick={handleClick}
                >
                    {typeof label === "string" && label.length <= 4 ? (
                        <Typography sx={{ width: "100%", fontWeight: 600 }}>
                            {label}
                        </Typography>
                    ) : (
                        <Stack width="100%">{label}</Stack>
                    )}
                </Button>
            </div>
        );
    }

    return (
        <div style={{ width: "100%" }}>
            <Button
                component={CLink}
                href={link as string}
                sx={styles}
                onClick={(e) => {
                    if (onClick) onClick(e);
                    handleClick();
                }}
            >
                <Stack width="100%">{label}</Stack>
            </Button>
        </div>
    );
}
