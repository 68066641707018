import {
    Button,
    CircularProgress,
    Container,
    Link as MuiLink,
    Stack,
    Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StripeElements from "../components/StripeElements";
import { postRequest } from "../utils/http";
import { theme } from "../utils/theme";

function usePaymentForm() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const stripe = useStripe();
    const elements = useElements();
    const [err, setErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        const cardElement = elements?.getElement(CardElement);

        if (!stripe || !elements || !cardElement) {
            return;
        }

        try {
            const stripeResponse = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            const { error, paymentMethod } = stripeResponse;

            if (error || !paymentMethod) {
                return;
            }

            const paymentMethodId = paymentMethod.id;

            setErr("");
            setIsLoading(true);

            const res = await postRequest("/payment/attach-card", {
                paymentMethodId,
            });

            const sc = await stripe?.confirmCardSetup(res?.client_secret);

            if (sc.error) {
                setErr("Couldn't authenticate card");
            } else {
                const url = params.get("redirect");

                if (url) {
                    // todo: check url
                    if (url.startsWith("http")) {
                        window.location.href = url;
                    } else {
                        navigate(url);
                    }
                }
            }
        } catch (error: any) {
            console.log(error);
            setErr("An unexpected error has occurred, try again");

            try {
                postRequest("/payment/report-error", {
                    error: JSON.stringify(error),
                })
                    .then((data) => {})
                    .catch((err) => {
                        console.log(error);
                    });
            } catch (error) {}
        }

        setIsLoading(false);
    };

    return {
        handleSubmit,
        isLoading,
        err,
    };
}

function PaymentForm() {
    const { handleSubmit, err, isLoading } = usePaymentForm();

    return (
        <Stack
            component="form"
            onSubmit={handleSubmit}
            maxWidth={400}
            width="100%"
            spacing={3}
            alignSelf="center"
        >
            <Typography variant="h6">Add a billing card</Typography>
            {!!err && (
                <Stack direction="column" spacing={2} py={2}>
                    <Typography color={theme.palette.error.main}>
                        {err}
                    </Typography>

                    <Typography bgcolor="rgb(238, 244, 253)" p={1}>
                        We are aware that some bank cards fail to be added, so
                        we recommend you try another card or we can enroll you
                        you manually by registering for the course via this
                        link:{" "}
                        <MuiLink
                            href="https://flutterwave.com/pay/p6det8mu9qs6"
                            color={theme.palette.common.black}
                            fontWeight="bold"
                        >
                            https://flutterwave.com/pay/p6det8mu9qs6
                        </MuiLink>{" "}
                        (Flutterwave, for Nigeria) OR{" "}
                        <MuiLink
                            href="https://buy.stripe.com/7sIeWT22O0Gd8la8wy"
                            color={theme.palette.common.black}
                            fontWeight="bold"
                        >
                            https://buy.stripe.com/7sIeWT22O0Gd8la8wy
                        </MuiLink>{" "}
                        (Stripe). Registration will be effective within 24 hours
                        and we will notify you you immediately.
                    </Typography>
                </Stack>
            )}

            <CardElement />

            <Button
                variant="contained"
                sx={styles.saveButton}
                size="large"
                disableElevation
                type="submit"
                disabled={isLoading}
            >
                Save card
                {isLoading && (
                    <CircularProgress
                        style={{ width: 20, height: 20, marginLeft: 20 }}
                    />
                )}
            </Button>
        </Stack>
    );
}

export default function AddCard() {
    return (
        <Stack
            component={Container}
            pt={{ xs: 3, sm: 4 }}
            pb={3}
            spacing={{ xs: 6, sm: 8 }}
        >
            <StripeElements>
                <PaymentForm />
            </StripeElements>
        </Stack>
    );
}

const styles = {
    saveButton: {
        alignSelf: "center",
        px: 3,
        textTransform: "none",
        fontWeight: "bold",
        color: "white",
    },
};
