import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./home/reducer";
import { HomeState } from "./home/types";
import accountReducer from "./account/reducer";
import { AccountState } from "./account/types";
import { ClassroomState } from "./classroom/types";
import classroomReducer from "./classroom/reducer";

interface State {
    home: HomeState;
    account: AccountState;
    classroom: ClassroomState;
}

export const store = configureStore<State, any>({
    reducer: {
        home: homeReducer,
        account: accountReducer,
        classroom: classroomReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
