import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import PROJECT from "../assets/icons/project.svg";
import { default as SLIDE, default as TEXT } from "../assets/icons/reading.svg";
import TEST from "../assets/icons/test.svg";
import VIDEO from "../assets/icons/video.svg";
import { CLASSROOM } from "../constants";

export const FILL_IN_THE_GAP_INDICATOR = "_BOX_";

export function extractResponseMessage(message: any) {
    if (Array.isArray(message)) {
        return message[0];
    }

    return message;
}

export function replaceBox(question: string, length: number) {
    let txt = question;

    for (let index = 0; index < length; index++) {
        txt = txt.replace(
            FILL_IN_THE_GAP_INDICATOR,
            `<span class='fill-in-gap-box'></span>`
        );
    }

    return txt;
}

export function isTouchDevice() {
    return (
        "ontouchstart" in window ||
        window.navigator.maxTouchPoints > 0 ||
        (window.navigator as any).msMaxTouchPoints > 0
    );
}

export function getSubmoduleMeta(type: SubmoduleType): {
    src: string;
    label: string;
} {
    switch (type) {
        case SubmoduleType.Text:
            return { src: TEXT, label: "Text" };
        case SubmoduleType.Project:
            return { src: PROJECT, label: "Project" };
        case SubmoduleType.Test:
            return { src: TEST, label: "Test" };
        case SubmoduleType.Video:
            return { src: VIDEO, label: "Video" };
        case SubmoduleType.Slide:
            return { src: SLIDE, label: "Reading" };
        default:
            return { src: TEXT, label: "Reading" };
    }
}

export function generateClassroomPath({
    courseId,
    moduleId,
    submoduleId,
}: {
    courseId?: number;
    moduleId?: number;
    submoduleId?: number;
}) {
    let link = CLASSROOM;

    if (courseId) {
        link += `/${courseId}`;
    }

    if (moduleId) {
        link += `/${moduleId}`;
    }

    if (submoduleId) {
        link += `?submodule=${submoduleId}`;
    }

    return link;
}
