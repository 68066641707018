import { Component } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = {
    seconds: number;
    redirect: string;
    navigate: NavigateFunction;
};

type State = {
    count: number;
};

class CountDownBase extends Component<Props, State> {
    private id: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            count: props.seconds,
        };
        this.id = null;
    }

    componentDidMount(): void {
        this.id = setInterval(() => {
            if (this.state.count !== 1) {
                return this.setState({ count: this.state.count - 1 });
            }

            this.props.navigate(this.props.redirect);
        }, 1000);
    }

    componentWillUnmount(): void {
        clearInterval(this.id);
    }

    render() {
        return <>{this.state.count}</>;
    }
}

export default function CountDown(props: {
    seconds: number;
    redirect: string;
}) {
    const navigate = useNavigate();

    return <CountDownBase {...props} navigate={navigate} />;
}
