import ShareIcon from "@mui/icons-material/Share";
import BorderBlueButton from "../../components/BorderBlueButton";
import MuiLink from "@mui/material/Link";

export default function ShareButton({ link }: { link: string }) {
    const hasShare = window.navigator.share as any;

    if (hasShare) {
        return (
            <BorderBlueButton
                endIcon={<ShareIcon />}
                onClick={() => {
                    navigator
                        .share({
                            title: "HackBio Course",
                            text: "Check out this course from HackBio",
                            url: link,
                        })
                        .then(() => console.log("Successful share"))
                        .catch((error) => console.log("Error sharing", error));
                }}
            >
                Share
            </BorderBlueButton>
        );
    }

    return (
        <MuiLink href={link} target="_blank" rel="noreferrer">
            <BorderBlueButton>Course Details</BorderBlueButton>
        </MuiLink>
    );
}
