import * as React from "react";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";

export default function LinearProgressBar({
    style,
    sx,
    ...props
}: LinearProgressProps & { value: number; style?: SxProps }) {
    return (
        <Box sx={{ display: "flex", alignItems: "center", ...style }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    {...props}
                    sx={{ background: "silver", color: "black", ...sx }}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    sx={{ color: "black", fontWeight: "bold" }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
