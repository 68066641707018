/**
 * This page will be displayed at /
 * Has 3 sections:
 * - Recommmendations: recommendations will be fetched from the api
 * - Courses:
 *     - User's on going subscription courses and subscription ended courses.
 * - Last course:
 *      No completed course will be displayed here, only ongoing subscribed course or subscription ended courses.
 *      What happens if there are no on going courses? display this https://www.figma.com/file/XIn8VVAiEbQuuPfecjNeIE/hbslack?type=design&node-id=2456-1725&mode=design&t=bOTFQEHoIZ4CPBop-0
 */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CSSProperties, Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { ReactComponent as CourseClickIcon } from "../assets/icons/course-click.svg";
import { AuthContext } from "../auth.context";
import CLink from "../components/CLink";
import CourseItem from "../components/CourseItem";
import Loading from "../components/Loading";
import Recommendations from "../components/Recommendations";
import { ALL_COURSES, COURSE_COLORS } from "../constants";
import { fetchCoursesCreator } from "../redux/home";
import { AppState } from "../redux/store";
import { AuthContextType } from "../types";
import {
    CourseResponse,
    UncompletedCoursesResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { getRequest } from "../utils/http";
import { computeProgress } from "@teyalite/hackbio-common/dist/compute-progress";
import { generateClassroomPath } from "../utils";

type Props = {} & PropsFromRedux;

type State = {};

class Home extends Component<Props, State> {
    static contextType = AuthContext;

    componentDidMount(): void {
        const { isLoading, courses, adsCourses } = this.props.store;

        if (!isLoading && courses.length === 0 && adsCourses.length === 0) {
            this.fetchCourses();
        }
    }

    fetchCourses = async () => {
        const { fetch } = this.props;
        fetch({ ...this.props.store, hasFailed: false, isLoading: true });

        try {
            const { courses, adsCourses } =
                await getRequest<UncompletedCoursesResponse>(
                    "/course?uncompleted=true&limit=2"
                );

            fetch({
                ...this.props.store,
                isLoading: false,
                courses,
                adsCourses,
            });
        } catch (error: any) {
            fetch({ ...this.props.store, hasFailed: true, isLoading: false });
        }
    };

    render() {
        const user = (this.context as AuthContextType).user!;
        const { isLoading, hasFailed, courses, adsCourses } = this.props.store;

        const lastCourse: CourseResponse | undefined = courses.find(
            (course) => true
        );

        return (
            <Stack component={Container} pt={{ xs: 3, sm: 4 }} spacing={3}>
                <Typography variant="h6" fontWeight="bold">
                    Hello {user.name},
                </Typography>

                <Stack direction="row" spacing={{ xs: 3, lg: 2.5, xl: 3 }}>
                    <Stack sx={styles.homeRoot} spacing={{ xs: 3, sm: 5 }}>
                        {isLoading || hasFailed ? (
                            <Stack py={4}>
                                <Loading
                                    failed={hasFailed}
                                    spacing={1}
                                    onRetry={this.fetchCourses}
                                />
                            </Stack>
                        ) : (
                            <>
                                <LastCourse course={lastCourse} />

                                {courses.length > 0 ? (
                                    <>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontWeight="bold"
                                                variant="h6"
                                            >
                                                Your courses
                                            </Typography>

                                            <CLink
                                                href="/classroom"
                                                sx={{ color: "black" }}
                                                underline="hover"
                                            >
                                                <Typography variant="h6">
                                                    See all →
                                                </Typography>
                                            </CLink>
                                        </Stack>

                                        <Stack
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                            spacing={{ xs: 2, sm: 1, md: 3 }}
                                        >
                                            {courses.map((course, index) => (
                                                <Box key={course.id} flex={1}>
                                                    <CourseItem
                                                        course={course}
                                                        color={
                                                            COURSE_COLORS[
                                                                index %
                                                                    COURSE_COLORS.length
                                                            ]
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </Stack>
                                    </>
                                ) : (
                                    <>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontWeight="bold"
                                                variant="h6"
                                            >
                                                You don't have any on-going
                                                course!
                                            </Typography>

                                            <MuiLink
                                                href={ALL_COURSES}
                                                sx={{ color: "black" }}
                                                target="_blank"
                                                rel="noreferrer"
                                                underline="hover"
                                            >
                                                <Typography variant="h6">
                                                    See all →
                                                </Typography>
                                            </MuiLink>
                                        </Stack>

                                        <Stack
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                            spacing={{ xs: 2, sm: 1, md: 3 }}
                                        >
                                            {adsCourses.map((course, index) => (
                                                <Box key={course.id} flex={1}>
                                                    <CourseItem
                                                        course={course}
                                                        external
                                                        color={
                                                            COURSE_COLORS[
                                                                index %
                                                                    COURSE_COLORS.length
                                                            ]
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </Stack>
                                    </>
                                )}

                                <CLink
                                    href="/classroom"
                                    sx={{
                                        color: "black",
                                        display: { xs: "flex", md: "none" },
                                    }}
                                    underline="hover"
                                >
                                    <Typography fontWeight="bold" variant="h6">
                                        See all →
                                    </Typography>
                                </CLink>
                            </>
                        )}

                        <Recommendations />
                    </Stack>

                    <Recommendations side />
                </Stack>
            </Stack>
        );
    }
}

function LastCourse({ course }: { course?: CourseResponse }) {
    if (!course) {
        const list = [
            "Learn by practice",
            "Real case study projects",
            "100% flexible and self-paced",
        ];

        return (
            <Stack sx={styles.lastCourseRoot}>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ background: "rgba(196, 196, 196, 0.2)" }}
                    p={2}
                >
                    <Typography
                        sx={{
                            flexGrow: 1,
                            fontWeight: "bold",
                            fontSize: { xs: "1.125rem", sm: "1.25rem" },
                        }}
                        variant="h6"
                    >
                        Get Unlimited access to all courses
                    </Typography>

                    <Button
                        sx={styles.resumeButton}
                        LinkComponent={"a"}
                        href={ALL_COURSES}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Start Now
                    </Button>
                </Stack>

                <Stack
                    sx={{ background: "#FBE98A" }}
                    p={2}
                    spacing={{ xs: 3, lg: 2 }}
                >
                    <Typography
                        fontSize={{ xs: "22px", sm: "2.125rem" }}
                        fontWeight="bold"
                    >
                        Master a new skill in 5 hours!
                    </Typography>
                    <Typography fontSize="23px">
                        Short courses that put you ahead for a long time 🚀!
                    </Typography>
                    <Stack component="ul" pl={4} spacing={1}>
                        {list.map((text, index) => (
                            <Typography
                                component="li"
                                key={index}
                                fontSize="20px"
                            >
                                {text}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        );
    }

    const progress = computeProgress(
        course.completedSubmodules,
        course.totalSubmodules
    );

    const link = generateClassroomPath({ courseId: course.id });

    return (
        <Stack sx={styles.lastCourseRoot}>
            <Stack
                direction="row"
                spacing={2}
                sx={{ background: "rgba(196, 196, 196, 0.2)" }}
                p={2}
            >
                <Typography
                    sx={{ flexGrow: 1, fontWeight: "bold" }}
                    variant="h6"
                >
                    You stopped here the last time
                </Typography>

                <Button
                    sx={styles.resumeButton}
                    LinkComponent={CLink}
                    href={link}
                >
                    Resume →
                </Button>
            </Stack>

            <Stack
                sx={{ background: "#FBE98A" }}
                direction={{ xs: "column", lg: "row" }}
                p={2}
                spacing={{ xs: 3, lg: 2 }}
            >
                <Stack flex={1}>
                    <Stack
                        className="bold-border"
                        sx={styles.lastCourseCoursePhotoBoxRoot}
                        spacing={2}
                    >
                        <Box
                            sx={styles.lastCourseCoursePhotoBox}
                            component={CLink}
                            href={link}
                        >
                            <CourseClickIcon
                                width={50}
                                height={50}
                                style={styles.lastCourseCoursePhotoBoxIcon}
                            />
                            <img
                                src={course.image}
                                width="100%"
                                height="100%"
                                alt={course.title}
                                className="bold-border"
                            />
                        </Box>

                        {/* <Typography fontWeight="bold">
                            {course.title}
                        </Typography> */}
                    </Stack>
                </Stack>

                <Stack flex={1} spacing={2} pb={3}>
                    <Typography variant="h6" fontWeight="bold">
                        {course.title}
                    </Typography>

                    <Typography display={{ xs: "none", md: "block" }}>
                        HackBio Course
                    </Typography>

                    <Typography variant="h6">
                        Progress:
                        <strong
                            className="text-green"
                            style={{ marginLeft: 10 }}
                        >
                            {progress}%
                        </strong>
                    </Typography>

                    <Typography variant="h6">
                        Points:
                        <strong className="text-xp" style={{ marginLeft: 10 }}>
                            {`${course.xp} XP`}
                        </strong>
                    </Typography>

                    <Box py={3}>
                        <CLink
                            href={link}
                            sx={styles.lastCourseAlmostThere}
                            underline="none"
                        >
                            🙌 You are almost there! →
                        </CLink>
                    </Box>

                    <Button
                        sx={styles.resumeButton2}
                        LinkComponent={CLink}
                        variant="contained"
                        color="info"
                        disableElevation
                        href={link}
                    >
                        Resume →
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}

const styles = {
    homeRoot: {
        flexGrow: 1,
        maxWidth: "726px",
    },
    lastCourseRoot: {
        border: "3px solid #000000",
        borderRadius: "5px",
    },
    resumeButton: {
        background: "#BDDCFB",
        color: "black",
        px: 3,
        textTransform: "none",
        display: { xs: "none", sm: "flex" },
        fontWeight: "bold",
    },
    resumeButton2: {
        color: "white",
        px: 3,
        display: { xs: "flex", sm: "none" },
        textTransform: "none",
        fontWeight: "bold",
    },
    lastCourseCoursePhotoBoxRoot: {
        background: "white",
        alignSelf: "center",
        p: 2,
        maxWidth: {
            xs: "364px",
            md: "426px",
            lg: "364px",
        },
    },
    lastCourseCoursePhotoBox: {
        position: "relative",
        height: {
            xs: "153.5px",
            sm: "198px",
            md: "236px",
            lg: "198px",
        },
        width: {
            // xs: "244.5px",
            xs: "100%",
            sm: "326px",
            md: "390px",
            lg: "326px",
        },
    },
    lastCourseCoursePhotoBoxIcon: {
        position: "absolute",
        zIndex: 100,
        left: "calc(50% - 25px)",
        top: "calc(50% - 25px)",
    } as CSSProperties,
    lastCourseAlmostThere: {
        color: "black",
        alignSelf: "flex-start",
        fontWeight: "bold",
    },
};

const mapDispatchToProps = {
    fetch: fetchCoursesCreator,
};

function mapStateToProps(state: AppState) {
    return { store: { ...state.home.courses } };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
