import { Button, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../constants";
import { theme } from "../../utils/theme";

export default function Checkout() {
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const courseId = useParams().courseId!;
    const [method, setMethod] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMethod((event.target as HTMLInputElement).value);
    };

    const diplayMode = () => {
        if (method === "flutterwave") {
            return <FlutterWave courseId={courseId} />;
        }

        if (method === "stripe") {
            return <Stripe courseId={courseId} />;
        }
    };

    return (
        <Stack
            component={Container}
            sx={styles.root}
            spacing={styles.rootPadding}
        >
            <FormControl sx={{ alignSelf: "center" }}>
                <Typography sx={{ mb: 1, fontSize: "x-large" }}>
                    Select payment method
                </Typography>
                <RadioGroup
                    value={method}
                    onChange={handleChange}
                    row={matches}
                >
                    <FormControlLabel
                        value="flutterwave"
                        control={<Radio />}
                        label={
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >
                                <img
                                    src="https://static.thehackbio.com/flutter-wave.png"
                                    width="150px"
                                    alt="Pay using flutterwave"
                                />
                                <Typography mt={0.7}>
                                    (For Nigerians)
                                </Typography>
                            </Stack>
                        }
                    />

                    <FormControlLabel
                        value="stripe"
                        sx={matches ? { ml: 5 } : { mt: 3 }}
                        control={<Radio />}
                        label={
                            <Stack direction="row" alignItems="center">
                                <img
                                    src="https://static.thehackbio.com/visa.png"
                                    width="50px"
                                    alt="Pay with visa"
                                />
                                <img
                                    src="https://static.thehackbio.com/mastercard.png"
                                    alt="Pay with master card"
                                    width="50px"
                                />
                                (<Typography>Outside Nigeria</Typography>)
                                {/* <img
                                    src="https://static.thehackbio.com/stripe.svg"
                                    width="50px"
                                    alt="Pay with stripe"
                                /> */}
                            </Stack>
                        }
                    />
                </RadioGroup>
            </FormControl>

            {diplayMode()}
        </Stack>
    );
}

function Stripe({ courseId }: { courseId: string }) {
    return (
        <Stack
            component="form"
            spacing={3}
            alignSelf="center"
            method="post"
            action={`${API_BASE_URL}/stripe/checkout`}
        >
            <Typography variant="h6">
                Pay with Visa, Mastercard, etc...
            </Typography>
            <input name="courseId" defaultValue={courseId} hidden />
            <Button
                variant="contained"
                sx={{ color: "white", fontWeight: "bold" }}
                disableElevation
                type="submit"
            >
                Pay
            </Button>
        </Stack>
    );
}

function FlutterWave({ courseId }: { courseId: string }) {
    return (
        <Stack
            component="form"
            spacing={2}
            alignSelf="center"
            method="post"
            action={`${API_BASE_URL}/flutterwave/checkout`}
        >
            <Typography variant="h5">Pay with Flutterwave</Typography>
            <input name="courseId" defaultValue={courseId} hidden />
            <FormControl style={{ display: "none" }}>
                <FormLabel sx={{ mb: 1 }}>Currency</FormLabel>
                <RadioGroup defaultValue="NGN" sx={{ ml: 3 }} name="currency">
                    <FormControlLabel
                        value="USD"
                        control={<Radio />}
                        label="United States Dollar (USD)"
                    />
                    <FormControlLabel
                        value="NGN"
                        control={<Radio />}
                        label="Nigerian Naira (NGN)"
                    />
                </RadioGroup>
            </FormControl>
            <Button
                variant="contained"
                sx={{ color: "white", fontWeight: "bold" }}
                disableElevation
                type="submit"
            >
                Pay
            </Button>
        </Stack>
    );
}

const styles = {
    rootPadding: { xs: 2, sm: 4, md: 2 },
    root: { pt: { xs: 3, sm: 4 }, pb: 3 },
};
