import React from "react";
import { RecommendationItem as RecommendationItemType } from "../types";
import Stack from "@mui/material/Stack";
import RecommendedItemIcon from "../assets/icons/recommended-item-course.svg";
import Typography from "@mui/material/Typography";

export default function RecommendedItemCourse({
    text,
    href,
    type,
}: RecommendationItemType) {
    return (
        <Stack
            component={"a"}
            href={href}
            target="_blank"
            rel="noreferrer"
            direction="row"
            spacing={2}
            py={1}
            px={1}
            sx={{
                "&:hover": { background: "rgb(189 220 251 / 22%)" },
                borderRadius: "5px",
            }}
        >
            <img src={RecommendedItemIcon} width={54} height={54} alt="" />
            <Stack spacing={1}>
                <Typography fontWeight="bold">{text}</Typography>
                <Typography
                    component="small"
                    fontSize={14}
                    sx={{ color: "#828282" }}
                >
                    {"HackBio " + (type === "course" ? "Course" : "Blog")}
                </Typography>
            </Stack>
        </Stack>
    );
}
