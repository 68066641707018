import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

export default function BackdropLoading({ open }: { open: boolean }) {
    return (
        <Backdrop
            sx={{
                color: "white",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <Stack spacing={8} alignItems="center">
                <Typography variant="h4" fontWeight={"bold"} className="blink">
                    HackBio
                </Typography>
                <div className="dot-revolution"></div>
            </Stack>
        </Backdrop>
    );
}
