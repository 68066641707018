export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const STRIPE_KEY = process.env
    .REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

export const MY_COURSES = "/classroom";

export const CLASSROOM = MY_COURSES;

export const HALL_OF_FAME = "/hall-of-fame";

export const MY_ACCOUNT = "/account";

// Base url for the api
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GOOGLE_LOGIN_URL = `${API_BASE_URL}/auth/google`;

export const GITHUB_LOGIN_URL = `${API_BASE_URL}/auth/github`;

// Error message when request network failed or Server Error
export const CONNECTION_FAILED = "Connection failed, try again!";

// Terms and conditions on auth pages
export const TERMS_AND_CONDITIONS =
    "https://assets.thehackbio.com/terms-privacy.html";

// Course colors for user courses
export const COURSE_COLORS = [
    "rgba(242, 201, 76, 0.5)",
    "rgba(189, 220, 251, 0.5)",
];

// Link to all hackbio courses
export const ALL_COURSES = "https://thehackbio.com/courses";

// Link to all hackbio bootcamps
export const BOOTCAMPS_URL = "https://thehackbio.com//bootcamps";

// HackBio Stories
export const STORIES_URL = "https://thehackbio.com/stories";

// HackBio Blog
export const BLOG_URL = "http://blog.thehackbio.com";

// HackBio events application url
export const EVENTS_URL = "https://events.thehackbio.com";

// Path finder
export const PATH_FINDER = "https://thehackbio.com/path-finder";

export const SPEAK_TO_MENTOR = "https://cal.com/hackbio-office-neg2ef/15min";

export const CONTACT_URL = "https://thehackbio.com/contact";

export const ABOUT_URL = "https://thehackbio.com/about";

export const SIGN_OUT = "";

// User profile image constants

export const IMAGE_PROCESS_FAIL_MESSAGE = "Image processing failed, try again";

export const SIZE_LIMIT_MESSAGE = "Image size too large";

export const INVALID_TYPE_MESSAGE = "Invalid image type";
