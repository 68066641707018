import { Dispatch } from "react";
import {
    ClassroomActionType,
    ClassroomCourseDetails,
    FetchCourse,
    UpdateSubmoduleType,
    UpdateSubmodule,
    UpdateTestSubmodule,
    UpdateTestSubmoduleType,
} from "./types";

export const fetchCourseDetailsCreator = (
    payload: ClassroomCourseDetails & { courseId: number }
) => {
    return (dispatch: Dispatch<FetchCourse>) => {
        dispatch({
            value: payload,
            type: ClassroomActionType.FetchCourse,
        });
    };
};

export const updateSubmoduleCreator = (payload: UpdateSubmoduleType) => {
    return (dispatch: Dispatch<UpdateSubmodule>) => {
        dispatch({
            value: payload,
            type: ClassroomActionType.UpdateSubmodule,
        });
    };
};

export const updateTestSubmoduleCreator = (
    payload: UpdateTestSubmoduleType
) => {
    return (dispatch: Dispatch<UpdateTestSubmodule>) => {
        dispatch({
            value: payload,
            type: ClassroomActionType.UpdateTestSubmodule,
        });
    };
};
