import { Stack, Typography } from "@mui/material";
import { arrayEquals } from "@teyalite/hackbio-common/dist/array-equals";
import {
    FillInGapAnswerReveal,
    TestSubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { ChangeEvent, useState } from "react";
import { TestStatus } from "../../../types";
import { replaceBox } from "../../../utils";
import QuestionBlueInput from "../../QuestionBlueInput";
import SubmitButton from "./SubmitButton";
import {
    CaseWarning,
    FeedbackSurvey,
    Reveal,
    Thanks,
    WrongText,
} from "./Verdict";

export default function FillInGap({
    test,
    status,
    submit,
}: {
    test: TestSubmoduleDetailsResponse;
    status: TestStatus;
    submit: (data: { fillInGap: { answers: string[] } }) => void;
}) {
    const [answers, setAnswers] = useState<string[]>(Array(test.size).fill(""));

    const [submitted, setSubmitted] = useState(false);

    const htmlContent = replaceBox(test.question, test.size ?? 0).split(
        /\r|\r|\n/g
    );

    const handleSubmit = () => {
        setSubmitted(true);
        submit({ fillInGap: { answers } });
    };

    const handleAnswerChange = (index: number, value: string) => {
        const ans = [...answers.slice()];

        ans[index] = value;

        setAnswers([...ans]);
    };

    const CONTENT = (
        <Typography component="div">
            {htmlContent.map((txt, index) => (
                <Stack
                    key={index}
                    alignItems="center"
                    direction="row"
                    component={"div"}
                    flexWrap={"wrap"}
                    sx={{ lineHeight: 3 }}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></Stack>
            ))}
        </Typography>
    );

    if (test.answer && test.xp === 0) {
        return (
            <Stack flexGrow={1} spacing={2}>
                <FeedbackSurvey />

                {CONTENT}

                <Thanks />
            </Stack>
        );
    }

    if (test.answer) {
        const ans = test.answer as FillInGapAnswerReveal;

        const ITEMS = status === "failed" && submitted ? answers : ans.list;

        return (
            <Stack flexGrow={1} spacing={2}>
                {CONTENT}

                <Stack spacing={1}>
                    <CaseWarning />

                    {ITEMS.map((value, index) => (
                        <InputItem
                            key={index}
                            disabled
                            index={index}
                            value={value}
                            onAnswerChange={handleAnswerChange}
                        />
                    ))}
                </Stack>

                <Reveal
                    correct={arrayEquals<string>(answers, ans.list)}
                    submitted={submitted}
                    explanation={ans.explanation}
                >
                    <Stack component="ul" marginLeft={2}>
                        {ans.list.map((txt, index) => (
                            <Typography
                                component="li"
                                key={index}
                                marginLeft={3}
                            >
                                {txt}
                            </Typography>
                        ))}
                    </Stack>
                </Reveal>
            </Stack>
        );
    }

    return (
        <Stack flexGrow={1} spacing={2}>
            {test.xp === 0 && <FeedbackSurvey />}

            {CONTENT}

            <Stack spacing={1}>
                {test.xp !== 0 && <CaseWarning />}

                {answers.map((value, index) => (
                    <InputItem
                        key={index}
                        index={index}
                        value={value}
                        onAnswerChange={handleAnswerChange}
                    />
                ))}
            </Stack>

            {status === "failed" && <WrongText />}

            <SubmitButton onClick={handleSubmit} loading={status === "loading"}>
                Submit Answer
            </SubmitButton>
        </Stack>
    );
}

type InputItemProps = {
    index: number;
    value: string;
    onAnswerChange: (index: number, value: string) => void;

    disabled?: boolean;
};

function InputItem({
    index,
    value,
    onAnswerChange,
    disabled = false,
}: InputItemProps) {
    const label = `Box ${index + 1} answer`;

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onAnswerChange(index, e.currentTarget.value);
    };

    return (
        <QuestionBlueInput
            placeholder={label}
            label={label}
            value={value}
            disabled={disabled}
            onChange={handleChange}
        />
    );
}
