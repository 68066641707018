import { CourseResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { Badge, Certificate } from "../../types";

export interface AccountHallOfFameState {
    isLoading: boolean;
    hasFailed: boolean;
    badges: Badge[];
    certificates: Certificate[];
}

export interface AccountMyCoursesState {
    isLoading: boolean;
    hasFailed: boolean;
    courses: CourseResponse[]; // completed and uncompleted courses
}

export interface AccountState {
    hallOfFame: AccountHallOfFameState;
    myCourses: AccountMyCoursesState;
}

export interface ResetAccountMyCoursesState {
    type: AccountActionType.ResetAccountMyCoursesState;
}

export interface FetchHallOfFame {
    type: AccountActionType.FetchHallOfFame;
    value: AccountHallOfFameState;
}

export interface FetchMyCourses {
    type: AccountActionType.FetchMyCourses;
    value: AccountMyCoursesState;
}

export enum AccountActionType {
    FetchHallOfFame = "AccountFetchHallOfFame",
    FetchMyCourses = "AccountFetchMyCourses",
    ResetAccountMyCoursesState = "AccountResetAccountMyCoursesState",
}

export type AccountAction =
    | FetchHallOfFame
    | FetchMyCourses
    | ResetAccountMyCoursesState;

export const defaultAccountState: AccountState = {
    hallOfFame: {
        isLoading: false,
        hasFailed: false,
        certificates: [],
        badges: [],
    },
    myCourses: {
        isLoading: false,
        hasFailed: false,
        courses: [],
    },
};
