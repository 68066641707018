import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SxProps } from "@mui/system";
import Container from "@mui/system/Container";
import { PropsWithChildren } from "react";
import LoginBg from "../../assets/icons/login-bg.svg";

export default function AuthLayout({
    children,
    sx,
}: PropsWithChildren<{ sx?: SxProps }>) {
    return (
        <Stack sx={styles.root} className="bgcolor-blue">
            <Stack direction={"row"} component={Container} maxWidth="lg">
                <Stack spacing={5} sx={{ ...styles.childrenBox, ...sx }}>
                    {children}
                </Stack>
                <Box sx={styles.imageBox}>
                    <img
                        src={LoginBg}
                        height={385}
                        width={500}
                        alt="HackBio Auth Background"
                    />
                </Box>
            </Stack>
        </Stack>
    );
}

const styles = {
    root: { width: "100%", height: "100%", overflow: "auto" },
    childrenBox: {
        flexGrow: 1,
        pb: 10,
        pt: { xs: 7, md: 10 },
        px: { md: 2, xl: 4 },
        mx: { xs: 0, sm: 8, md: 0 },
    },
    imageBox: {
        width: "50%",
        height: "100vh",
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
    },
};
