import Stack from "@mui/material/Stack";
import { StatusCodes } from "http-status-codes";
import { Component, PropsWithChildren, createContext, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AppBarLayout from "./components/AppBarLayout";
import FailedRetry from "./components/FailedRetry";
import HBLoading from "./components/HBLoading";
import { AuthContextType, User } from "./types";
import { getRequest } from "./utils/http";

export const AuthContext = createContext<AuthContextType>({
    user: null,
    loading: true,
    failed: false,
    retry: () => {},
    setUser: () => {},
});

export class AuthContextProvider extends Component<
    PropsWithChildren,
    { user: User | null; loading: boolean; failed: boolean }
> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = { user: null, loading: true, failed: false };
    }

    componentDidMount(): void {
        this.fetchUser();
    }

    fetchUser = async () => {
        this.setState({
            loading: true,
            failed: false,
        });

        try {
            const user = await getRequest<User>("/auth");

            this.setState({
                loading: false,
                failed: false,
                user: user,
            });
        } catch (error: any) {
            this.setState({
                failed: !(
                    error.response &&
                    error.response.status === StatusCodes.UNAUTHORIZED
                ),
                loading: false,
            });
        }
    };

    setUser = (user: User) => {
        this.setState({ user });
    };

    render() {
        const { failed, user, loading } = this.state;
        const { children } = this.props;

        return (
            <AuthContext.Provider
                value={{
                    user,
                    loading,
                    failed,
                    retry: this.fetchUser,
                    setUser: this.setUser,
                }}
            >
                {children}
            </AuthContext.Provider>
        );
    }
}

/**
 * Protect all routes except login route
 * @returns
 */
export function Protected() {
    const { user, loading, failed, retry } = useContext(AuthContext);

    if (loading || failed) {
        return (
            <Stack
                width="100%"
                height="100%"
                pb={15}
                alignItems="center"
                justifyContent="center"
            >
                {failed ? <FailedRetry onRetry={retry} /> : <HBLoading />}
            </Stack>
        );
    }

    if (user) {
        return (
            <AppBarLayout>
                <Outlet />
            </AppBarLayout>
        );
    }

    return <Navigate to="/auth/login" />;
}
