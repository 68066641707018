import LockIcon from "@mui/icons-material/Lock";
import { Stack, Typography } from "@mui/material";

export default function SubmoduleLocked() {
  return (
    <Stack alignItems="center" py={3} spacing={3}>
      <LockIcon style={{ fontSize: 30 }} />
      <Stack textAlign="center" spacing={1}>
        <Typography variant="h5">Restricted Access</Typography>
        <Typography fontWeight="bold">Enroll to get access to it</Typography>
      </Stack>
    </Stack>
  );
}
