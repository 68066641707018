import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { MAX_PROJECT_GRADE } from "@teyalite/hackbio-common/dist/constants";
import {
    CourseSubmoduleResponse,
    ProjectSubmissionResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { Fragment } from "react";
import { theme } from "../../utils/theme";

type ShowSubmissionProps = {
    project: CourseSubmoduleResponse;
    submission: ProjectSubmissionResponse;
    onClose: () => void;
};

export default function ShowSubmission({
    project,
    submission,
    onClose,
}: ShowSubmissionProps) {
    return (
        <Fragment>
            <Stack spacing={0.1}>
                <Typography variant="h6">Project:</Typography>
                <Typography variant="h6">
                    <strong>{project.title}</strong>
                </Typography>
            </Stack>

            <Stack spacing={0.1}>
                <Typography variant="h6">Submission:</Typography>
                <MuiLink
                    href={submission.url}
                    target="_blank"
                    rel="noreferrer"
                    underline="hover"
                    alignSelf="flex-start"
                    color={theme.palette.info.dark}
                    sx={{ wordBreak: "break-word" }}
                >
                    <strong>{submission.url}</strong>
                </MuiLink>
            </Stack>

            <Stack spacing={0.1}>
                <Typography variant="h6">Grade:</Typography>
                <Typography variant="h6">
                    <strong>
                        {submission.grade
                            ? `${submission.grade}/${MAX_PROJECT_GRADE}`
                            : "Project has not been graded yet"}
                    </strong>
                </Typography>
            </Stack>

            {submission.comments && (
                <Stack spacing={0.1}>
                    <Typography variant="h6">Comments:</Typography>
                    <Typography>
                        {submission.comments.length > 0 ? (
                            submission.comments.map((txt, key) => (
                                <Fragment key={key}>
                                    {txt}
                                    <br />
                                </Fragment>
                            ))
                        ) : (
                            <Typography>
                                There are no reviewer comments
                            </Typography>
                        )}
                    </Typography>
                </Stack>
            )}

            <div />

            <Button
                onClick={onClose}
                color="error"
                variant="outlined"
                disableElevation
                sx={styles.closeButton}
            >
                Close
            </Button>
        </Fragment>
    );
}

const styles = {
    closeButton: {
        textTransform: "none",
        fontWeight: "bold",
        display: { xs: "block", sm: "none" },
    },
};
