import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import RecommendedItem from "../components/RecommendedItemCourse";
import { ALL_COURSES } from "../constants";
import { fetchRecommendationsCreator } from "../redux/home";
import { AppState } from "../redux/store";
import { getRequest } from "../utils/http";
import Loading from "./Loading";

type Props = { side?: boolean } & PropsFromRedux;

class Recommendations extends Component<Props> {
    componentDidMount(): void {
        const { isLoading, items } = this.props.store;
        if (!isLoading && items.length === 0) {
            this.fetchRecommendations();
        }
    }

    fetchRecommendations = async () => {
        const { fetch } = this.props;

        fetch({ ...this.props.store, hasFailed: false, isLoading: true });

        try {
            const recommendations = await getRequest("/recommendations");

            fetch({
                ...this.props.store,
                isLoading: false,
                items: recommendations,
            });
        } catch (error: any) {
            fetch({ ...this.props.store, hasFailed: true, isLoading: false });
        }
    };

    render() {
        const { side, store } = this.props;
        const { items, isLoading, hasFailed } = store;

        return (
            <Stack sx={styles.root(Boolean(side))}>
                <Typography
                    sx={{
                        background: "rgba(196, 196, 196, 0.2)",
                        fontWeight: "bold",
                    }}
                    p={2}
                >
                    Recommended!
                </Typography>

                {isLoading || hasFailed ? (
                    <Stack py={4}>
                        <Loading
                            failed={hasFailed}
                            spacing={1}
                            onRetry={this.fetchRecommendations}
                        />
                    </Stack>
                ) : (
                    <Stack spacing={2} px={2} py={2}>
                        {items.length === 0 && (
                            <Typography>No recommendation</Typography>
                        )}
                        {true &&
                            items.map((item, index) => (
                                <RecommendedItem key={index} {...item} />
                            ))}
                    </Stack>
                )}

                <MuiLink
                    sx={{ mx: 2, my: 2, alignSelf: "flex-start" }}
                    underline="hover"
                    target="_blank"
                    rel="noreferrer"
                    href={ALL_COURSES}
                >
                    See all Courses →
                </MuiLink>
            </Stack>
        );
    }
}

const styles = {
    root: (side: boolean) => ({
        border: "3px solid #000000",
        borderRadius: "5px",
        width: { xs: "100%", lg: "350px" },
        pb: 3,
        alignSelf: "flex-start",
        display: side ? { xs: "none", lg: "flex" } : { lg: "none", xs: "flex" },
    }),
};

const mapDispatchToProps = {
    fetch: fetchRecommendationsCreator,
};

function mapStateToProps(state: AppState) {
    return { store: { ...state.home.recommendations } };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Recommendations);
