import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as CourseChairIcon } from "../assets/icons/course-chair.svg";
import CLink from "./CLink";
import LinearProgressBar from "./LinearProgressBar";
import { CourseResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { computeProgress } from "@teyalite/hackbio-common/dist/compute-progress";
import { generateClassroomPath } from "../utils";

type Props = {
    color?: string;
    course: CourseResponse;
    external?: boolean;
};

export default function CourseItem({ color, course, external = false }: Props) {
    const link = external
        ? course.fullLink
        : generateClassroomPath({ courseId: course.id });

    const progress = computeProgress(
        course.completedSubmodules,
        course.totalSubmodules
    );

    const lProps = {
        href: link,
        ...(external
            ? {
                  component: "a",
                  target: "_blank",
                  rel: "noreferrer",
              }
            : {
                  component: CLink,
              }),
    };

    return (
        <Stack
            className="bold-border"
            sx={{
                // width: { xs: "350px", sm: "400px", md: "358px" },
                background: color,
                textDecoration: "none",
                color: "inherit",
            }}
            p={2}
            spacing={3}
            {...lProps}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <CourseChairIcon width={50} height={50} />
                <Typography>{external ? "Start Now" : "Continue"} →</Typography>
            </Stack>
            <Typography variant="h6" fontWeight="bold">
                {/* Genomic Epidemiology of Infections */}
                {course.title}
            </Typography>
            <Stack spacing={2}>
                <LinearProgressBar value={progress} />
                <Stack direction="row" spacing={3} fontWeight="bold">
                    <Typography className="text-green">
                        ⌛️ {progress}%
                    </Typography>
                    <Typography>🚀 {course.xp}XP</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
