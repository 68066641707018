import { RecommendationItem } from "../../types";
import { CourseResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";

export interface HomeCoursesState {
    isLoading: boolean;
    hasFailed: boolean;
    courses: CourseResponse[];
    adsCourses: CourseResponse[];
}

export interface HomeRecommendationsState {
    isLoading: boolean;
    hasFailed: boolean;
    items: RecommendationItem[];
}

export interface HomeState {
    recommendations: HomeRecommendationsState;
    courses: HomeCoursesState;
}

export interface FetchRecommendations {
    type: HomeActionType.FetchRecommendations;
    value: HomeRecommendationsState;
}

export interface FetchCourses {
    type: HomeActionType.FetchCourses;
    value: HomeCoursesState;
}

export interface ResetHomeCoursesState {
    type: HomeActionType.ResetHomeCoursesState;
}

export enum HomeActionType {
    FetchRecommendations = "HomeFetchRecommendations",
    FetchCourses = "HomeFetchCourses",
    ResetHomeCoursesState = "HomeResetHomeCoursesState",
}

export type HomeAction =
    | FetchRecommendations
    | FetchCourses
    | ResetHomeCoursesState;

export const defaultHomeState: HomeState = {
    recommendations: {
        isLoading: false,
        hasFailed: false,
        items: [],
    },
    courses: {
        isLoading: false,
        hasFailed: false,
        courses: [],
        adsCourses: [],
    },
};
