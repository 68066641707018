import { ReactComponent as Logo } from "../assets/icons/hackbio.svg";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";

export default function HBLoading() {
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="blink"
        >
            <Logo width={60} height={60} />
            <Typography variant="h4" fontWeight={"bold"} color="primary">
                HackBio
            </Typography>
        </Stack>
    );
}
