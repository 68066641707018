import { Link as MuiLink, Stack, Typography } from "@mui/material";
import { emailValidation } from "@teyalite/hackbio-common/dist/email-validtation";
import { StatusCodes } from "http-status-codes";
import { ChangeEvent, Component, FormEvent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import BlueButton from "../../components/BlueButton";
import Input from "../../components/Input";
import BackdropLoading from "../../components/auth/BackdropLoading";
import AuthLayout from "../../components/auth/Layout";
import { CONNECTION_FAILED } from "../../constants";
import { postRequest } from "../../utils/http";
import { theme } from "../../utils/theme";

type State = {
    errorMessage: string;

    email: string;
    emailError: boolean;
    emailErrorMessage: string;

    success: boolean;
    isResending: boolean;
    isLoading: boolean;
    triggered: boolean;
};

type Props = {};

export default class Login extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",

            email: "",
            emailError: true,
            emailErrorMessage: emailValidation(""),

            isResending: false,
            isLoading: false,
            success: false,
            triggered: false,
        };
    }

    /**
     * Handle email field changes
     * @param param0
     */
    onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = emailValidation(value);

        this.setState({
            email: value,
            emailError: Boolean(message),
            emailErrorMessage: message,
        });
    };

    /**
     * Submit recovery form
     * @param event
     * @returns
     */
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { emailError, email } = this.state;

        if (emailError) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ isLoading: true, errorMessage: "" });

        try {
            await postRequest("/auth/password-recovery", { email });
            this.setState({ isLoading: false, success: true });
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                error.response.status === StatusCodes.NOT_FOUND
            ) {
                message = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            this.setState({
                isLoading: false,
                errorMessage: message,
            });
        }
    };

    /**
     * Send recovery email again
     * @param e
     */
    sendConfirmation = async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const { email } = this.state;

        this.setState({ isResending: true });

        try {
            await postRequest("/auth/password-recovery", { email });
        } catch (error) {}

        this.setState({ isResending: false });
    };

    render() {
        const {
            email,
            errorMessage,
            emailErrorMessage,
            emailError,
            success,
            isLoading,
            triggered,
            isResending,
        } = this.state;

        const emailErr = emailError && triggered;

        if (success) {
            return (
                <AuthLayout>
                    <Typography variant="h4" fontWeight="bold">
                        Check Your Email!
                    </Typography>
                    <Typography variant="h5" maxWidth={"600px"}>
                        Check your email:{" "}
                        <span style={{ fontWeight: "bold" }}>{email}</span> for
                        instructions from us on how to reset your password.
                    </Typography>
                    <Typography>
                        {"Didn't"} receive the email?{" "}
                        {!isResending && (
                            <MuiLink
                                href={"#"}
                                color={theme.palette.info.main}
                                onClick={this.sendConfirmation}
                                fontWeight="bold"
                                underline="hover"
                            >
                                resend the email
                            </MuiLink>
                        )}
                        {isResending && <strong>resending</strong>}
                    </Typography>
                    <Typography sx={styles.backToLoginBox}>
                        Never mind!{" "}
                        <MuiLink
                            component={Link}
                            to="/auth/login"
                            sx={styles.backToLoginLink}
                        >
                            Take me back to login
                        </MuiLink>
                    </Typography>
                </AuthLayout>
            );
        }

        return (
            <AuthLayout>
                <BackdropLoading open={isLoading} />
                <Typography sx={styles.title}>Reset your password</Typography>
                <Typography
                    variant="h6"
                    className="text-gray"
                    sx={{
                        maxWidth: "420px",
                    }}
                >
                    To reset your password, enter the email address you use to
                    sign in.
                </Typography>
                <Stack spacing={4}>
                    {errorMessage && (
                        <Typography sx={styles.errorMessage}>
                            {errorMessage}
                        </Typography>
                    )}

                    <Stack
                        spacing={3}
                        maxWidth="400px"
                        component="form"
                        onSubmit={this.onSubmit}
                    >
                        <Input
                            placeholder="Email"
                            onChange={this.onEmailChange}
                            value={email}
                            error={emailErr}
                            helperText={emailErr ? emailErrorMessage : ""}
                            size="medium"
                        />

                        <BlueButton type="submit">Get reset link</BlueButton>
                    </Stack>
                </Stack>
                <Typography sx={styles.backToLoginBox}>
                    Never mind!{" "}
                    <MuiLink
                        component={Link}
                        to="/auth/login"
                        sx={styles.backToLoginLink}
                    >
                        Take me back to login
                    </MuiLink>
                </Typography>
            </AuthLayout>
        );
    }
}

const styles = {
    title: {
        fontWeight: "bold",
        fontSize: { xs: "1.75rem", sm: "2.125rem" },
    },
    errorMessage: {
        color: theme.palette.error.main,
        maxWidth: "420px",
    },
    backToLoginBox: {
        fontWeight: "bold",
        fontSize: { xs: "1rem", sm: "1.25rem" },
    },
    backToLoginLink: {
        color: theme.palette.info.main,
        textDecorationColor: theme.palette.info.main,
    },
};
