import {
    ClassroomAction,
    ClassroomActionType,
    ClassroomState,
    defaultClassroomState,
} from "./types";

function classroomReducer(
    state: ClassroomState = defaultClassroomState,
    action: ClassroomAction
): ClassroomState {
    switch (action.type) {
        case ClassroomActionType.FetchCourse: {
            const { courseId, ...other } = action.value;

            state = {
                ...state,
                courseId,
                details: {
                    ...state.details,
                    ...other,
                },
            };
            return state;
        }

        case ClassroomActionType.UpdateSubmodule: {
            const { submoduleIndex, xp, moduleIndex } = action.value;

            const modules = state.details.course!.modules.slice();

            const submodules = modules[moduleIndex].submodules.slice();

            submodules[submoduleIndex] = {
                ...modules[moduleIndex].submodules[submoduleIndex],
                completed: true,
            };

            modules[moduleIndex] = {
                ...modules[moduleIndex],
                xp: modules[moduleIndex].xp + xp,
                completedSubmodules:
                    modules[moduleIndex].completedSubmodules + 1,
                submodules: [...submodules],
            };

            state = {
                ...state,
                details: {
                    ...state.details,
                    course: {
                        ...state.details.course!,
                        xp: state.details.course!.xp + xp,
                        completedSubmodules:
                            state.details.course!.completedSubmodules + 1,
                        modules: [...modules],
                    },
                },
            };

            return state;
        }

        case ClassroomActionType.UpdateTestSubmodule: {
            const { xp, completed, moduleId, submoduleId } = action.value;

            const course = { ...state.details.course! };

            const modules = course.modules.slice();

            const moduleIndex = modules.findIndex((mo) => mo.id === moduleId);

            const submodules = modules[moduleIndex].submodules.slice();

            const submoduleIndex = submodules.findIndex(
                (sub) => sub.id === submoduleId
            );

            const module = { ...modules[moduleIndex] };

            module.xp += xp;
            course.xp += xp;

            if (completed) {
                module.completedSubmodules += 1;
                course.completedSubmodules += 1;
            }

            submodules[submoduleIndex] = {
                ...submodules[submoduleIndex],
                completed,
            };

            modules[moduleIndex] = {
                ...modules[moduleIndex],
                ...module,
                submodules: [...submodules],
            };

            state = {
                ...state,
                details: {
                    ...state.details,
                    course: {
                        ...course,
                        modules: [...modules],
                    },
                },
            };

            return state;
        }

        default:
            return state;
    }
}

export default classroomReducer;
