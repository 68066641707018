import { CourseDetailsResponse } from "@teyalite/hackbio-common/dist/types/client-responses.interface";

export interface UpdateSubmoduleType {
    submoduleIndex: number;
    moduleIndex: number;
    xp: number;
}

export interface UpdateTestSubmoduleType {
    moduleId: number;
    submoduleId: number;
    testId: number;
    xp: number;
    completed: boolean;
}

export interface ClassroomCourseDetails {
    isLoading: boolean;
    hasFailed: boolean;
    course?: CourseDetailsResponse | null;
}

export interface ClassroomState {
    courseId?: number;
    details: ClassroomCourseDetails;
}

export interface FetchCourse {
    type: ClassroomActionType.FetchCourse;
    value: ClassroomCourseDetails & { courseId: number };
}

export interface UpdateSubmodule {
    type: ClassroomActionType.UpdateSubmodule;
    value: UpdateSubmoduleType;
}

export interface UpdateTestSubmodule {
    type: ClassroomActionType.UpdateTestSubmodule;
    value: UpdateTestSubmoduleType;
}

export enum ClassroomActionType {
    FetchCourse = "ClassroomFetchCourse",
    UpdateSubmodule = "ClassroomUpdateSubmodule",
    UpdateTestSubmodule = "ClassroomUpdateTestSubmodule",
}

export type ClassroomAction =
    | FetchCourse
    | UpdateSubmodule
    | UpdateTestSubmodule;

export const defaultClassroomState: ClassroomState = {
    details: { isLoading: false, hasFailed: false },
};
