import {
  USERNAME_CHARACTERS_ERROR,
  USERNAME_FIELD,
  USERNAME_LENGTH_ERROR_MESSAGE,
  USERNAME_REGEX,
  USER_USERNAME_MAX_LENGTH,
  USER_USERNAME_MIN_LENGTH,
} from './constants';
import { requiredErrorMessage } from './error-messages';

/**
 * Validate username
 * @param name
 * @returns
 */
export function usernameValidation(username: string) {
  if (username.length === 0) {
    return requiredErrorMessage(USERNAME_FIELD);
  }

  if (
    username.length > USER_USERNAME_MAX_LENGTH ||
    username.length < USER_USERNAME_MIN_LENGTH
  ) {
    return USERNAME_LENGTH_ERROR_MESSAGE;
  }

  if (!USERNAME_REGEX.test(username)) {
    return USERNAME_CHARACTERS_ERROR;
  }

  return '';
}
