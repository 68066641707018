import { Dispatch } from "react";
import {
    AccountActionType,
    AccountHallOfFameState,
    AccountMyCoursesState,
    FetchHallOfFame,
    FetchMyCourses,
    ResetAccountMyCoursesState,
} from "./types";

export const fetchHallOfFameCreator = (payload: AccountHallOfFameState) => {
    return (dispatch: Dispatch<FetchHallOfFame>) => {
        dispatch({
            value: payload,
            type: AccountActionType.FetchHallOfFame,
        });
    };
};

export const fetchMyCoursesCreator = (payload: AccountMyCoursesState) => {
    return (dispatch: Dispatch<FetchMyCourses>) => {
        dispatch({
            value: payload,
            type: AccountActionType.FetchMyCourses,
        });
    };
};

export const resetAccountMyCoursesStateCreator = () => {
    return (dispatch: Dispatch<ResetAccountMyCoursesState>) => {
        dispatch({
            type: AccountActionType.ResetAccountMyCoursesState,
        });
    };
};
