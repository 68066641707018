import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  CourseSubmoduleResponse,
  ProjectSubmissionResponse,
  SubmoduleDetailsResponse,
} from "@teyalite/hackbio-common/dist/types/client-responses.interface";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import { Component, forwardRef } from "react";
import { RichHtml, SlideContent, VideoContent } from "../../types";
import { getRequest } from "../../utils/http";
import Loading from "../Loading";
import SubmoduleNotFound from "../SubmoduleNotFound";
import SlideShow from "./SlideShow";
import SubmoduleTest from "./SubmoduleTest";
import VideoShow from "./VideoShow";
import ProjectSubmissionForm from "./ProjectSubmissionForm";
import { ModuleType } from "@teyalite/hackbio-common/dist/types/module-type.enum";
import SubmoduleLocked from "../SubmoduleLocked";

type Props = {
  submodules: CourseSubmoduleResponse[];
  submodule: CourseSubmoduleResponse;
  moduleType: ModuleType;
  innerRef?: any;
};

type State = {
  submodule: SubmoduleDetailsResponse | null;
  isLoading: boolean;
  hasFailed: boolean;
  openSubmission: boolean;
};

class SubmoduleContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      submodule: null,
      isLoading: true,
      hasFailed: false,
      openSubmission: false,
    };
  }

  componentDidMount(): void {
    this.fetchSubmodule();
  }

  fetchSubmodule = async () => {
    this.setState({ isLoading: true, hasFailed: false });
    const {
      submodule: { id, moduleId, courseId },
    } = this.props;

    try {
      const sub = await getRequest<SubmoduleDetailsResponse>(
        `/course/submodule?courseId=${courseId}&moduleId=${moduleId}&submoduleId=${id}`
      );

      this.setState({ isLoading: false, submodule: sub });
    } catch (error: any) {
      this.setState({ hasFailed: true, isLoading: false });
    }
  };

  childrenSetSubmodule = (sub: SubmoduleDetailsResponse) => {
    this.setState({ submodule: sub });
  };

  childrenSetSubmission = (submission: ProjectSubmissionResponse) => {
    this.setState({ submodule: { ...this.state.submodule!, submission } });
  };

  projectSubmit = async () => {
    this.setState({ openSubmission: true });
  };

  handleFormClose = () => this.setState({ openSubmission: false });

  render() {
    const { isLoading, hasFailed, submodule } = this.state;

    if (isLoading || hasFailed) {
      return (
        <Stack py={4}>
          <Loading
            failed={hasFailed}
            spacing={1}
            onRetry={this.fetchSubmodule}
          />
        </Stack>
      );
    }

    if (!submodule) {
      return <SubmoduleLocked />;
    }

    if (submodule.type === SubmoduleType.Slide) {
      const content = submodule.content as SlideContent;
      return <SlideShow src={content.slideUrl} title={submodule.title} />;
    }

    if (submodule.type === SubmoduleType.Video) {
      const content = submodule.content as VideoContent;
      return <VideoShow src={content.videoUrl} title={submodule.title} />;
    }

    if (submodule.type === SubmoduleType.Text) {
      const content = submodule.content as RichHtml;

      return (
        <Stack
          sx={{
            maxHeight: { xs: "auto", md: "550px" },
            overflowY: "auto",
            position: "relative",
            px: { xs: 0.5, sm: 1 },
            py: 1,
          }}
        >
          <Preview content={content} />
        </Stack>
      );
    }

    if (submodule.type === SubmoduleType.Project) {
      const content = submodule.content as RichHtml;
      const submission = submodule.submission;

      return (
        <Stack
          sx={{
            maxHeight: { xs: "auto", md: "550px" },
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Preview content={content} />

          {this.state.openSubmission && (
            <ProjectSubmissionForm
              onClose={this.handleFormClose}
              submission={submission}
              submodules={this.props.submodules}
              moduleType={this.props.moduleType}
              currentSubmodule={this.props.submodule}
              setSubmission={this.childrenSetSubmission}
            />
          )}

          <button
            ref={this.props.innerRef}
            hidden
            onClick={this.projectSubmit}
          />
        </Stack>
      );
    }

    if (submodule.type === SubmoduleType.Test) {
      return (
        <SubmoduleTest
          submodule={submodule}
          setSubmodule={this.childrenSetSubmodule}
        />
      );
    }

    return null;
  }
}

function Preview({ content }: { content: RichHtml }) {
  return (
    <Box
      component="div"
      dangerouslySetInnerHTML={{ __html: content.html }}
      sx={{ px: { xs: 0.25, sm: 1 }, pt: { xs: 0.25, sm: 1 }, pb: 3 }}
    />
  );
}

export default forwardRef(function (props: Props, ref: any) {
  return <SubmoduleContent {...props} innerRef={ref} />;
});
